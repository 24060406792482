
import React from "react";
import {
    Grid, Paper, List, ListItemText, ListItemButton,
    Typography, TextField, Box, Button,
    Divider, Container
} from "@mui/material";

import ApplicationContext from '../contexts/application';
import { getSettingCategories, getSettings, saveSettings } from "../libs/apiCalls";

function Settings() {
    const { settings, setDialog, setSnackbar } = React.useContext(ApplicationContext);
    const [appSettings, setAppSettings] = React.useState([]);
    const [categories, setCategories] = React.useState([]);
    const [groups, setGroups] = React.useState([]);
    const [isDirty, setIsDirty] = React.useState(false);
    const [selectedIndex, setSelectedIndex] = React.useState(-1);

    React.useEffect(() => {
        const unloadCallback = (event) => {
            if (isDirty) {
                event.preventDefault();
                event.returnValue = "";
                return "";
            }
        };

        window.addEventListener("beforeunload", unloadCallback);
        return () => window.removeEventListener("beforeunload", unloadCallback);
    }, [isDirty]);

    React.useEffect(() => {
        if (Object.keys(settings).length > 0) {
            getSettingCategories(settings.token,
                (response) => {
                    if (!response.data.error) {
                        setCategories(JSON.parse(response.data.result));
                    }
                    else {
                        setDialog(response.data.errors[0], true);
                    }
                },
                (error) => {
                    setDialog(error.message, true);
                }
            );
        }
    }, [settings]);

    React.useEffect(() => {
        if (appSettings.length > 0) {
            const grps = appSettings.map(item => item.group).filter((value, index, self) => self.indexOf(value) === index);
            let components = [];

            grps.forEach((grp, index) => {
                const grpItems = appSettings.filter(a => a.group === grp);

                components.push(
                    <Paper key={index} sx={{ display: 'flex', flexDirection: 'column', p: 2, mb: 2 }}>
                        <Typography component="h2" variant="h6" color="primary" sx={{ mb: 1 }}>{grp}</Typography>

                        <Grid>
                            {grpItems.map((setting, idx) => (
                                <TextField key={idx} label={setting.description} variant="standard"
                                    defaultValue={setting.value} sx={{ width: '45%', mx: 1, my: 1.5 }}
                                    type={setting.description.includes("Password") ? "password" : ""}
                                    name={setting.name}
                                    onChange={handleChange} />
                            ))}
                        </Grid>

                    </Paper>
                );
            });

            setGroups(components);
        }
    }, [appSettings]);

    const handleCategoryClick = (event, index) => {
        if (!isDirty) {
            setGroups([]);
            setSelectedIndex(index);

            getSettings(settings.token, event.target.innerText,
                (response) => {
                    if (!response.data.error) {
                        setAppSettings(JSON.parse(response.data.result));
                    }
                    else {
                        setDialog(response.data.errors[0], true)
                    }
                },
                (error) => {
                    setDialog(error.message, true);
                }
            );
        }
        else {
            setDialog('Save changes before continuing!', true);
        }
    };

    const handleSave = (event) => {
        event.preventDefault();
        saveSettings(settings.token, settings.userName, appSettings,
            (response) => {
                if (!response.data.error) {
                    setSnackbar('Settings saved successfully.');
                }
                else {
                    setDialog(response.data.errors[0], true);
                }
            },
            (error) => {
                setDialog(error.message, true);
            }
        );

        setIsDirty(false);
    };

    const handleChange = (event) => {
        let item = appSettings.find(s => s.name === event.target.name);
        item.value = event.target.value;

        if (!isDirty) {
            setIsDirty(true);
        }
    };

    return (
        <>
            <Box sx={{ display: 'flex', mb: 2 }}>
                <Box sx={{ width: "50%" }}>
                    <Typography sx={{ marginLeft: "5px", fontWeight: '400' }} style={{ fontSize: "25px" }}>Settings</Typography>
                    <Typography sx={{ marginLeft: "5px", fontWeight: '300' }} style={{ fontSize: "15px" }}>Application settings and configurations.</Typography>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: "50%", mb: 4 }}>
                    <Button variant="contained" color="success" style={{ width: "120px", marginLeft: "7px" }} onClick={handleSave}>Save</Button>
                </Box>
            </Box>
            <Divider sx={{ mb: 4 }} />
            <Container maxWidth="xl">

                <Grid container spacing={2}>
                    <Grid item xs={12} md={12} lg={3}>
                        <Paper
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                minHeight: 200,
                                component: 'form'
                            }}
                        >
                            <Typography component="h2" variant="h6" color="primary" sx={{ ml: 2, mt: 2 }}>Category</Typography>

                            <List component="nav">
                                {categories.map((item, index) => (
                                    <ListItemButton key={index} 
                                        onClick={(event) => handleCategoryClick(event, index)} 
                                        selected={index === selectedIndex} 
                                        sx={{ '&.Mui-selected': { backgroundColor: "#f7fbf1" } }}
                                    >
                                        <ListItemText primary={item} />
                                    </ListItemButton>
                                ))}
                            </List>
                        </Paper>
                    </Grid>

                    <Grid item xs={12} md={12} lg={9} >
                        {groups}
                    </Grid>
                </Grid>
            </Container>
        </>
    );
}

export default Settings;