
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

import ApplicationContext from './contexts/application';
import { getToken } from './libs/apiCalls';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { loginRequest } from './authConfig';
import { Container, useMediaQuery } from '@mui/material';

import { UserRoles } from './libs/userRoles';

function Login() {
    const { settings, saveSettings } = useContext(ApplicationContext);
    const { instance, accounts } = useMsal();
    const authenticated = useIsAuthenticated();
    const smallScreen = useMediaQuery('(min-height: 800px)');

    const navigate = useNavigate();
    const enableSso = `${process.env.REACT_APP_SSO_ENABLED}` === 'true';

    const handleUserChange = (event) => {
        const value = event.target.value;
        if (Object.keys(settings).length > 0) {
            settings.userName = value;
        }
    };

    const handlePasswordChange = (event) => {
        const value = event.target.value;
        if (Object.keys(settings).length > 0) {
            settings.password = value;
        }
    };

    const handleSubmit = (event) => {
        getToken(settings, (response) => {
            const user = JSON.parse(response.data.result);

            let roles = [];
            if (user.oem) {
                roles = [UserRoles.OEM.label];
            }
            else if (user.administrator) {
                roles = [UserRoles.ADMIN.label];
            }
            else {
                roles = user.alarmGroups.map(group => group.userRole);

                if (user.operator) {
                    roles.push(UserRoles.OPERATOR.label);
                }
            }

            settings.token = user.token;
            settings.roles = roles;
            settings.userName = user.userName;
            settings.name = user.firstName + " " + user.lastName;

            saveSettings(settings);

            (settings.roles?.includes(UserRoles.ADMIN.label) || settings.roles?.includes(UserRoles.OEM.label)) ?
                navigate("/admin") :
                navigate("/user");

        }, (error) => {
            alert(error.message);
        });
    };

    const handleLogin = () => {
        instance.loginPopup(loginRequest)
            .catch(e => {
                console.log(e);
            });
    };

    React.useEffect(() => {
        const account = accounts[0];
        if (authenticated && settings.token !== account.idToken) {

            settings.userName = account.username;
            settings.name = account.name;
            settings.token = account.idToken;
            settings.roles = account.idTokenClaims.roles;
            settings.oauth = "microsoft";

            saveSettings(settings);

            settings.roles?.includes(UserRoles.ADMIN.label) ? navigate("/admin") : navigate("/user");
        }
    }, [authenticated]);

    return (
        <>
            <div className={"login-container"} sx={{ opacity: 0.1 }}>
                <div className={"login-left"}>
                </div>
                <div className={"login-right"}>
                    <Box component="form" className="login-box">
                        <img style={{ width: '350px', marginTop: '20px', marginBottom: '30px' }} src="nlhs.svg" alt="nlhs logo" />

                        <h2>Welcome</h2>
                        <div>
                            <Button autoFocus variant="contained" onClick={handleLogin} sx={{ width: "100%" }} disabled={!enableSso}>Sign in with Microsoft</Button>
                        </div>
                        <div>
                            <h3>OR</h3>
                            <h5>Please login with your credentials to access the system.</h5>

                            <TextField required label="Username" sx={{ width: "100%" }} onChange={handleUserChange} />
                            <TextField required label="Password" type="password" autoComplete="off" sx={{ width: "100%" }} onChange={handlePasswordChange} onKeyDown={(e) => { if (e.key === 'Enter') { handleSubmit(e); } }} />
                            <Button variant="contained" onClick={(e) => handleSubmit(e)} sx={{ width: "100%" }}>Login</Button>
                        </div>
                    </Box>
                </div>
            </div>
            <Container component="div" style={{ alignContent: 'center', margin: 20, top: 'auto', bottom: '20px', right: 'auto', position: 'fixed', width: '100%', display: smallScreen ? 'block' : 'none' }}>
                <img style={{ width: '350px' }} src="nlhs-white.svg" alt="nlhs logo" />
            </Container>
        </>
    );
}

export default Login;

