
import React from "react";
import { useNavigate } from "react-router";

import { Avatar, Box, Card, CardActions, CardContent, Container, Divider, Grid, IconButton, Pagination, Typography } from "@mui/material";
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

import ApplicationContext from '../contexts/application';
import { deleteUser, getUsers } from "../libs/apiCalls";
import ConfirmDialog from "../components/ConfirmDialog";
import { UserRoles } from "../libs/userRoles";

function Users() {
    const { settings, setDialog, setSnackbar } = React.useContext(ApplicationContext);
    const navigate = useNavigate();

    const [userCards, setUserCards] = React.useState([]);
    const [currentPage, setCurrentPage] = React.useState(0);
    const [users, setUsers] = React.useState([]);
    const [userId, setUserId] = React.useState(0);
    const [showConfirmDelete, setShowConfirmDelete] = React.useState(false);
    const [reload, setReload] = React.useState(false);
    const [isOem, setIsOem] = React.useState(settings.roles?.includes(UserRoles.OEM.label));

    const [pageCount, setPageCount] = React.useState(0);
    const perPage = React.useRef(10);

    const newUser = {
        userName: '',
        password: '',
        firstName: '',
        lastName: '',
        tokenExpiry: 600000,
        administrator: false,
        createdBy: settings.userName
    };
    
    React.useEffect(() => {
        if (Object.keys(settings).length > 0) {
            // get users
            getUsers(settings.token,
                (response) => {
                    let totalRecords = JSON.parse(response.data.result).length;
                    let remainder = totalRecords % perPage.current;

                    setPageCount(Math.floor(totalRecords / perPage.current) + (remainder > 0 ? 1 : 0));
                    setUsers(JSON.parse(response.data.result));
                },
                (error) => {
                    setPageCount(0);
                    setDialog(error.message, true);
                }
            );
        }
    }, [settings, reload]);

    React.useEffect(() => {
        let components = [];

        if (users.length > 0) {
            let start = currentPage * perPage.current;
            let end = perPage.current * (currentPage + 1);
            let display = users.slice(start, end);

            display.map((user, index) => (
                components.push(userCard(user, index))
            ));

            setUserCards(components);
        }
    }, [currentPage, users]);

    const userCard = (user, index) => {
        return (
            <Grid item key={index} sx={{ display: isOem ? 'block' : (user.oem ? 'none' : 'block') }}>
                <Card variant="outlined" sx={{ width: 300, minHeight: 320, boxShadow: 3 }} >
                    <CardContent>
                        <Box sx={{ display: 'flex', mb: 4 }}>
                            <Box sx={{ width: "30%" }}>
                                <Avatar sx={{ width: 60, height: 60, mb: 2 }} />
                            </Box>
                            <Box sx={{ justifyContent: 'flex-end', width: "50%", mb: 4, mr: 1, minHeight: '70px' }}>
                                <Typography variant="h5">{user.userName}</Typography>
                                <Typography sx={{ mb: 1.5 }} color="text.secondary">{user.firstName} {user.lastName}</Typography>
                            </Box>
                        </Box>
                        <Typography variant="body2" sx={{ mb: 1 }}>Roles:</Typography>
                        <Typography variant="body2" sx={{ mb: 1 }}>Last login:</Typography>
                        <Typography variant="body2">Timeout: {user.tokenExpiry / 1000 / 60}</Typography>
                    </CardContent>
                    <CardActions disableSpacing sx={{ justifyContent: 'right' }}>
                        <IconButton aria-label="edit user" onClick={() => {navigate("/admin/user-profile", { state: user })}}>
                            <ModeEditIcon fontSize="medium" color='primary' />
                        </IconButton>
                        <IconButton aria-label="delete user" onClick={(e) => handleDeleteClick(e, user.userId)}>
                            <DeleteOutlineOutlinedIcon fontSize="medium" color='error' />
                        </IconButton>
                    </CardActions>
                </Card>
            </Grid>
        );
    };

    const handlePageChange = (event, page) => {
        setCurrentPage(page - 1);
    };

    const handleDeleteClick = (event, userId) => {
        setUserId(userId);
        setShowConfirmDelete(true);
    };

    const handleConfirmDelete = () => {
        setShowConfirmDelete(false);
        deleteUser(settings.token, userId,
            () => {
                setReload(!reload);
                setSnackbar('User deleted successfully.');
            },
            (error) => {
                setDialog(error.message, true);
            }
        );
    };

    return (
        <>
            <Box sx={{ display: 'flex', mb: 2 }}>
                <Box sx={{ width: "50%" }}>
                    <Typography sx={{ marginLeft: "5px", fontWeight: '400' }} style={{ fontSize: "25px" }}>Users</Typography>
                    <Typography sx={{ marginLeft: "5px", fontWeight: '300' }} style={{ fontSize: "15px" }}>Manage console access and logins.</Typography>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: "50%", mb: 2 }}>
                    <IconButton aria-label='add user' onClick={() => {navigate("/admin/user-profile", { state: newUser })}}>
                        <AddCircleOutlinedIcon sx={{ width: 40, height: 40, color: '#2e7d32' }} />
                    </IconButton>
                </Box>
            </Box>
            <Divider sx={{ mb: 4 }} />
            <Container maxWidth="lg">
                <Grid container spacing={2} sx={{ minHeight: "70vh", justifyContent: 'center' }}>
                    {userCards}
                </Grid>
                <Box sx={{ display: 'flex', justifyContent: 'center', width: "100%", mt: 4, mb: 4 }}>
                    <Pagination count={pageCount} variant="outlined" shape="rounded" onChange={handlePageChange} />
                </Box>
            </Container>

            <ConfirmDialog open={showConfirmDelete} title={"Confirm Delete"}
                content={"Are you sure you want to remove this user? (Note: this cannot be undone)"}
                onNoClick={() => setShowConfirmDelete(false)}
                onYesClick={handleConfirmDelete}
                yesNo
            />
        </>
    );
}

export default Users;