import React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';

import LogoutIcon from '@mui/icons-material/Logout';
import InboxIcon from '@mui/icons-material/Inbox';

import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';
import { Divider, List } from '@mui/material';
import { Link } from 'react-router-dom';


export function UserMenuItems({ menuOpen }) {

    return (
        <List component="nav" aria-labelledby="main-menu" subheader={
            <ListSubheader component="div" id="main-menu" sx={{ display: menuOpen ? 'block' : 'none' }}>Main Menu</ListSubheader>
        }>
            <ListItemButton component={Link} to={"/user"}>
                <ListItemIcon>
                    <NotificationImportantIcon />
                </ListItemIcon>
                <ListItemText primary="Alarm Viewer" />
            </ListItemButton>

            <Divider sx={{ my: 1 }} />

            <ListSubheader component="div" inset>
            </ListSubheader>

            <ListItemButton component={Link} to={"/signout"}>
                <ListItemIcon>
                    <LogoutIcon />
                </ListItemIcon>
                <ListItemText primary="Sign Out" />
            </ListItemButton>
        </List>
    );
}
