import React from "react";
import { useNavigate } from "react-router-dom";

import ApplicationContext from "../contexts/application";
import { UserRoles } from "../libs/userRoles";

function Protected({children}) {
    const { settings } = React.useContext(ApplicationContext);
    const navigate = useNavigate();

    React.useEffect(() => {
        if (Object.keys(settings).length > 0) {
            if (settings.token === "" || (!settings.roles?.includes(UserRoles.ADMIN.label) && !settings.roles?.includes(UserRoles.OEM.label) && !settings.roles?.includes(UserRoles.OPERATOR.label))) {
                navigate("/");
            }
        }
    });

    return (
        children
    );
};

export default Protected;
