import React from "react";
import { Box, Button, Container, 
    Divider, FormControl, Grid, 
    InputLabel, MenuItem, Paper, 
    Select, TextField, Typography } from "@mui/material";
import ApplicationContext from "../contexts/application";
import { useLocation, useNavigate } from "react-router";
import { getSourceFields, saveMessageSource } from "../libs/apiCalls";
import { CustomDataGrid } from "../components/CustomDataGrid";
import { UserRoles } from "../libs/userRoles";

function RoutingDetails() {
    const { settings, setDialog, setSnackbar } = React.useContext(ApplicationContext);
    const { state: source } = useLocation();
    const navigate = useNavigate();

    const [isDirty, setIsDirty] = React.useState(false);
    const [format, setFormat] = React.useState(source.format);
    const [gridRows, setGridRows] = React.useState([]);

    const columns = [
        { field: 'sourceFieldId', headerName: 'Id', flex: 0.2 },
        { field: 'fieldName', headerName: 'Field Name', flex: 0.3 },
        { field: 'sourceField', headerName: 'Value', editable: true, flex: 1 }
    ];

    const handleCancel = () => {
        navigate('/admin/routing');
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        setIsDirty(false);

        source.updatedBy = settings.userName;
        source.updatedDate = new Date();
        source.alarmFilters = [];
        
        saveMessageSource(settings.token, source, 
            (response) => {
                if (!response.data.error) {
                    setIsDirty(false);
                    setSnackbar('Record added/updated successfully.');
                    navigate('/admin/routing');
                }
                else {
                    setDialog(response.data.errors[0], true);
                }
            },
            (error) => {
                setDialog(error.message, true);
            }
        );
    };

    const handleFormatChange = (event) => {
        source.format = event.target.value;
        setFormat(event.target.value);
    };

    const handleOnChange = (event) => {
        source[event.target.name] = event.target.value;

        if (!isDirty) {
            setIsDirty(true);
        }
    };

    const processRowUpdate = React.useCallback((newRow, oldRow) => {
        if (newRow.sourceField !== oldRow.sourceField) {
            const rowId = newRow.sourceFieldId;
            const item = source.fields.find((f) => f.sourceFieldId === rowId);

            item.sourceField = newRow.sourceField;
            item.updatedBy = settings.userName;
            item.updatedDate = new Date();

            setIsDirty(true);
        }
        return newRow;
    }, []);
    
    React.useEffect(() => {        
        if (Object.keys(settings).length > 0 && source.sourceId) {
            // TODO: create another protected control
            if (!settings.roles?.includes(UserRoles.OEM.label)) {
                navigate('/');
            }

            getSourceFields(settings.token, source.sourceId,
                (response) => {
                    if (!response.data.error) {
                        setGridRows(JSON.parse(response.data.result));
                    }
                    else {
                        setDialog(response.data.errors[0], true);
                    }
                },
                (error) => {
                    setDialog(error.message, true);
                }
            );
        }
    }, [settings]);

    React.useEffect(() => {
        const unloadCallback = (event) => {
            if (isDirty) {
                event.preventDefault();
                event.returnValue = "";
                return "";
            }
        };

        window.addEventListener("beforeunload", unloadCallback);
        return () => window.removeEventListener("beforeunload", unloadCallback);
    }, [isDirty]);

    return (
        <>
            <form onSubmit={handleSubmit}>
                <Box sx={{ display: 'flex', mb: 2 }}>
                    <Box sx={{ width: "50%" }}>
                        <Typography sx={{ marginLeft: '5px', fontWeight: '400', fontSize: '25px' }}>Message Source Data</Typography>
                        <Typography sx={{ marginLeft: '5px', fontWeight: '300', fontSize: '15px' }}>Source / Message Source Data</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: "50%", mb: 4 }}>
                        <Button variant="outlined" color="error" style={{ width: "120px" }} onClick={handleCancel}>Cancel</Button>
                        <Button variant="contained" color="success" style={{ width: "120px", marginLeft: "7px" }} type='submit'>Save</Button>
                    </Box>
                </Box>
                <Divider sx={{ mb: 4 }} />
                <Container maxWidth="lg">
                    <Paper spacing={2} 
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            boxShadow: 2
                        }}
                    >
                        <Typography component="h2" variant="h6" color="primary" sx={{ ml: 2, mt: 2 }}>Details</Typography>

                        <Grid container padding={3} sx={{gap: 2}}>
                            <Grid item sx={{display: 'flex', flexDirection: 'row', gap: 2, width: '100%'}}>
                                <TextField
                                    required
                                    variant='standard'
                                    id='name'
                                    name='name'
                                    label='Name'
                                    type='text'
                                    defaultValue={source.name}
                                    onChange={handleOnChange}
                                    sx={{ flex: 0.3 }}
                                />
                                <TextField
                                    variant='standard'
                                    id='description'
                                    name='description'
                                    label='Description'
                                    type='text'
                                    defaultValue={source.description}
                                    onChange={handleOnChange}
                                    sx={{ flex: 1 }}
                                />
                            </Grid>
                            <Grid item sx={{display: 'flex', flexDirection: 'row', gap: 2, width: '100%'}}>
                                <FormControl variant="standard" sx={{ flex: 0.3 }}>
                                    <InputLabel id="format-label">Format / Source</InputLabel>
                                    <Select
                                        labelId="format-label"
                                        id="format-select"
                                        value={format}
                                        onChange={handleFormatChange}
                                    >
                                        <MenuItem value='JSON'>JSON</MenuItem>
                                        <MenuItem value='TAP'>TAP</MenuItem>
                                        <MenuItem value='UDP'>UDP</MenuItem>
                                        <MenuItem value='TCP'>TCP</MenuItem>
                                        <MenuItem value='API'>API</MenuItem>
                                    </Select>
                                </FormControl>
                                <TextField
                                    variant='standard'
                                    id='delimiter'
                                    name='delimiter'
                                    label='Data delimiter'
                                    type='text'
                                    defaultValue={source.delimiter}
                                    onChange={handleOnChange}
                                    sx={{ flex: 1 }}
                                />                                
                            </Grid>
                            <Grid item sx={{display: 'flex', flexDirection: 'row', gap: 2, width: '100%'}}>
                                <TextField
                                    variant='standard'
                                    id='topicIn'
                                    name='topicIn'
                                    label='Topic In'
                                    type='text'
                                    defaultValue={source.topicIn}
                                    onChange={handleOnChange}
                                    sx={{ flex: 0.3 }}
                                />
                                <TextField
                                    variant='standard'
                                    id='topicOut'
                                    name='topicOut'
                                    label='Topic Out'
                                    type='text'
                                    defaultValue={source.topicOut}
                                    onChange={handleOnChange}
                                    sx={{ flex: 1 }}
                                />                                
                            </Grid>    
                            <Grid item sx={{display: format === 'API' ? 'flex' : 'none', flexDirection: 'row', gap: 2, width: '100%' }}>
                                <TextField
                                    variant='standard'
                                    id='rawMessage'
                                    name='rawMessage'
                                    label='API Message'
                                    type='text'
                                    multiline
                                    rows={4}
                                    defaultValue={source.rawMessage}
                                    onChange={handleOnChange}
                                    sx={{ flex: 1 }}
                                    helperText='For API endpoints, this is the message body to send.'
                                />                               
                            </Grid>                                                      
                        </Grid>
                    </Paper>
                    <Paper spacing={2} 
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            boxShadow: 2,
                            mt: 2
                        }}
                    >
                        <Typography component="h2" variant="h6" color="primary" sx={{ ml: 2, mt: 2 }}>Data Definitions</Typography>
                        <Grid container padding={3} sx={{gap: 2}}>
                            <CustomDataGrid
                                columns={columns}
                                rows={gridRows}

                                getRowId={(row) => row.sourceFieldId}
                                getRowClassName={(params) =>
                                    params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                                }

                                hideFooterPagination={true}
                                processRowUpdate={processRowUpdate}
                                onProcessRowUpdateError={(error) => console.log(error)}
                            />
                        </Grid>
                    </Paper>
                </Container>
            </form>
        </>
    );
}

export default RoutingDetails;