import React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';

import DashboardIcon from '@mui/icons-material/Dashboard';
import PeopleIcon from '@mui/icons-material/People';
import DoorbellIcon from '@mui/icons-material/Doorbell';
import InboxIcon from '@mui/icons-material/Inbox';
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions';
import LogoutIcon from '@mui/icons-material/Logout';
import EngineeringIcon from '@mui/icons-material/Engineering';
import MonitorHeartIcon from '@mui/icons-material/MonitorHeart';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import TypeSpecimenIcon from '@mui/icons-material/TypeSpecimen';
import SourceIcon from '@mui/icons-material/Source';
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import GroupsIcon from '@mui/icons-material/Groups';
import AltRouteIcon from '@mui/icons-material/AltRoute';
import PageviewIcon from '@mui/icons-material/Pageview';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import TerminalIcon from '@mui/icons-material/Terminal';
import StickyNote2Icon from '@mui/icons-material/StickyNote2';

import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Collapse, Divider, List } from '@mui/material';
import { Link } from 'react-router-dom';



export function OemMenuItems({ menuOpen }) {
    const [alarmOpen, setAlarmOpen] = React.useState(false);
    const [integrationOpen, setIntegrationOpen] = React.useState(false);
    const [viewerOpen, setViewerOpen] = React.useState(false);
    const [lookupOpen, setLookupOpen] = React.useState(false);

    const handleAlarmClick = () => {
        setAlarmOpen(!alarmOpen);
    }

    const handleIntegrationClick = () => {
        setIntegrationOpen(!integrationOpen);
    }

    const handleViewerClick = () => {
        setViewerOpen(!viewerOpen);
    }

    const handleLookupClick = () => {
        setLookupOpen(!lookupOpen);
    }

    return (
        <List component="nav" aria-labelledby="main-menu" subheader={
            <ListSubheader component="div" id="main-menu" sx={{ display: menuOpen ? 'block' : 'none' }}>Main Menu</ListSubheader>
        }>
            <ListItemButton component={Link} to={"/admin"}>
                <ListItemIcon>
                    <DashboardIcon />
                </ListItemIcon>
                <ListItemText primary="Dashboard" />
            </ListItemButton>

            <ListItemButton component={Link} to={"/admin/service-health"}>
                <ListItemIcon>
                    <MonitorHeartIcon />
                </ListItemIcon>
                <ListItemText primary="Services" />
            </ListItemButton>

            <ListItemButton onClick={handleIntegrationClick}>
                <ListItemIcon>
                    <SourceIcon />
                </ListItemIcon>
                <ListItemText primary="Integrations" />
                {integrationOpen ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>

            <Collapse in={integrationOpen} timeout="auto" unmountOnExit>
                <List component="div" disablePadding sx={{ ml: 2 }}>
                    <ListItemButton component={Link} to={"/admin/sources"}>
                        <ListItemIcon>
                            <IntegrationInstructionsIcon />
                        </ListItemIcon>
                        <ListItemText primary="Sources" />
                    </ListItemButton>

                    <ListItemButton component={Link} to={"/admin/routing"}>
                        <ListItemIcon>
                            <AltRouteIcon />
                        </ListItemIcon>
                        <ListItemText primary="Routing" />
                    </ListItemButton>
                </List>
            </Collapse>

            <ListItemButton onClick={handleAlarmClick}>
                <ListItemIcon>
                    <DoorbellIcon />
                </ListItemIcon>
                <ListItemText primary="Alarms" />
                {alarmOpen ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>

            <Collapse in={alarmOpen} timeout="auto" unmountOnExit>
                <List component="div" disablePadding sx={{ ml: 2 }}>
                    <ListItemButton component={Link} to={"/admin/alarmPriorities"}>
                        <ListItemIcon>
                            <ImportExportIcon />
                        </ListItemIcon>
                        <ListItemText primary="Priorities" />
                    </ListItemButton>

                    <ListItemButton component={Link} to={"/admin/alarmGroups"}>
                        <ListItemIcon>
                            <GroupsIcon />
                        </ListItemIcon>
                        <ListItemText primary="Groups" />
                    </ListItemButton>

                    <ListItemButton component={Link} to={"/admin/alarmTypes"}>
                        <ListItemIcon>
                            <TypeSpecimenIcon />
                        </ListItemIcon>
                        <ListItemText primary="Types" />
                    </ListItemButton>
                </List>
            </Collapse>

            <ListItemButton onClick={handleViewerClick}>
                <ListItemIcon>
                    <PageviewIcon />
                </ListItemIcon>
                <ListItemText primary="Viewers" />
                {viewerOpen ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>

            <Collapse in={viewerOpen} timeout="auto" unmountOnExit>
                <List component="div" disablePadding sx={{ ml: 2 }}>
                    <ListItemButton component={Link} to={"/user"}>
                        <ListItemIcon>
                            <NotificationImportantIcon />
                        </ListItemIcon>
                        <ListItemText primary="Alarms" />
                    </ListItemButton>

                    <ListItemButton component={Link} to={"/admin/messages"}>
                        <ListItemIcon>
                            <InboxIcon />
                        </ListItemIcon>
                        <ListItemText primary="Messages" />
                    </ListItemButton>
                    <ListItemButton component={Link} to={"/admin/logs"}>
                        <ListItemIcon>
                            <StickyNote2Icon />
                        </ListItemIcon>
                        <ListItemText primary="Logs" />
                    </ListItemButton>                      
                </List>
            </Collapse>

            <ListItemButton onClick={handleLookupClick}>
                <ListItemIcon>
                    <MenuBookIcon />
                </ListItemIcon>
                <ListItemText primary="Data Lookups" />
                {lookupOpen ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>

            <Collapse in={lookupOpen} timeout="auto" unmountOnExit>
                <List component="div" disablePadding sx={{ ml: 2 }}>
                    <ListItemButton component={Link} to={"/admin/lookups"}>
                        <ListItemIcon>
                            <TerminalIcon />
                        </ListItemIcon>
                        <ListItemText primary="System" />
                    </ListItemButton>

                    <ListItemButton component={Link} to={"/admin/locations"}>
                        <ListItemIcon>
                            <MeetingRoomIcon />
                        </ListItemIcon>
                        <ListItemText primary="Locations" />
                    </ListItemButton>
                </List>
            </Collapse>

            <ListItemButton component={Link} to={"/admin/users"}>
                <ListItemIcon>
                    <PeopleIcon />
                </ListItemIcon>
                <ListItemText primary="Users" />
            </ListItemButton>

            <ListItemButton component={Link} to={"/admin/settings"}>
                <ListItemIcon>
                    <EngineeringIcon />
                </ListItemIcon>
                <ListItemText primary="Settings" />
            </ListItemButton>

            <Divider sx={{ my: 1 }} />

            <ListSubheader component="div" inset></ListSubheader>

            <ListItemButton component={Link} to={"/signout"}>
                <ListItemIcon>
                    <LogoutIcon />
                </ListItemIcon>
                <ListItemText primary="Sign Out" />
            </ListItemButton>
        </List>

    );
}


export function AdminMenuItems({ menuOpen, isAdmin }) {
    const [alarmOpen, setAlarmOpen] = React.useState(false);
    const [viewerOpen, setViewerOpen] = React.useState(false);

    const handleAlarmClick = () => {
        setAlarmOpen(!alarmOpen);
    }

    const handleViewerClick = () => {
        setViewerOpen(!viewerOpen);
    }

    return (
        <List component="nav" aria-labelledby="main-menu" subheader={
            <ListSubheader component="div" id="main-menu" sx={{ display: menuOpen ? 'block' : 'none' }}>Main Menu</ListSubheader>
        }>
            <ListItemButton component={Link} to={"/admin"}>
                <ListItemIcon>
                    <DashboardIcon />
                </ListItemIcon>
                <ListItemText primary="Dashboard" />
            </ListItemButton>

            <ListItemButton component={Link} to={"/admin/service-health"} sx={{ display: isAdmin ? 'flex' : 'none' }}>
                <ListItemIcon>
                    <MonitorHeartIcon />
                </ListItemIcon>
                <ListItemText primary="Services" />
            </ListItemButton>

            <ListItemButton onClick={handleAlarmClick} sx={{ display: isAdmin ? 'flex' : 'none' }}>
                <ListItemIcon>
                    <DoorbellIcon />
                </ListItemIcon>
                <ListItemText primary="Alarms" />
                {alarmOpen ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>

            <Collapse in={alarmOpen} timeout="auto" unmountOnExit>
                <List component="div" disablePadding sx={{ ml: 2 }}>
                    <ListItemButton component={Link} to={"/admin/alarmGroups"}>
                        <ListItemIcon>
                            <GroupsIcon />
                        </ListItemIcon>
                        <ListItemText primary="Groups" />
                    </ListItemButton>

                    <ListItemButton component={Link} to={"/admin/alarmTypes"}>
                        <ListItemIcon>
                            <TypeSpecimenIcon />
                        </ListItemIcon>
                        <ListItemText primary="Types" />
                    </ListItemButton>
                </List>
            </Collapse>

            <ListItemButton onClick={handleViewerClick}>
                <ListItemIcon>
                    <PageviewIcon />
                </ListItemIcon>
                <ListItemText primary="Viewers" />
                {viewerOpen ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>

            <Collapse in={viewerOpen} timeout="auto" unmountOnExit>
                <List component="div" disablePadding sx={{ ml: 2 }}>
                    <ListItemButton component={Link} to={"/user"}>
                        <ListItemIcon>
                            <NotificationImportantIcon />
                        </ListItemIcon>
                        <ListItemText primary="Alarms" />
                    </ListItemButton>

                    <ListItemButton component={Link} to={"/admin/messages"}>
                        <ListItemIcon>
                            <InboxIcon />
                        </ListItemIcon>
                        <ListItemText primary="Messages" />
                    </ListItemButton>


                    <ListItemButton component={Link} to={"/admin/logs"}>
                        <ListItemIcon>
                            <StickyNote2Icon />
                        </ListItemIcon>
                        <ListItemText primary="Logs" />
                    </ListItemButton>                    
                </List>
            </Collapse>

            <ListItemButton component={Link} to={"/admin/locations"} sx={{ display: isAdmin ? 'flex' : 'none' }}>
                <ListItemIcon>
                    <MeetingRoomIcon />
                </ListItemIcon>
                <ListItemText primary="Data Lookup" />
            </ListItemButton>

            <ListItemButton component={Link} to={"/admin/users"} sx={{ display: isAdmin ? 'flex' : 'none' }}>
                <ListItemIcon>
                    <PeopleIcon />
                </ListItemIcon>
                <ListItemText primary="Users" />
            </ListItemButton>

            <Divider sx={{ my: 1 }} />

            <ListSubheader component="div" inset></ListSubheader>

            <ListItemButton component={Link} to={"/signout"}>
                <ListItemIcon>
                    <LogoutIcon />
                </ListItemIcon>
                <ListItemText primary="Sign Out" />
            </ListItemButton>
        </List>

    );

}
