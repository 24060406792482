import React from 'react';

import {
    Box, Button, Card, CardActionArea, CardContent,
    Container, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid,
    Menu,
    MenuItem,
    Stack,
    Switch,
    Tooltip, Typography
} from '@mui/material';

import NotificationsIcon from '@mui/icons-material/Notifications';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import NotInterestedIcon from '@mui/icons-material/NotInterested';

import ApplicationContext from './contexts/application';
import { CustomDataGrid } from './components/CustomDataGrid';
import { clearAlarm, clearAllAlarms, getAlarmTotals, getFilteredAlarms } from './libs/apiCalls';
import dayjs from 'dayjs';
import AppLog from './admin/applog';
import CustomFilter from './components/CustomFilter';
import { useLocalStorage } from './libs/useLocalStorage';
import ConfirmDialog from './components/ConfirmDialog';
import { UserRoles } from './libs/userRoles';
import Link from '@mui/material/Link';

function AlarmViewer() {
    const defaultPageSize = 20;
    const lastWeek = new Date().getDate() - 7;
    const defaultColVisibility = {
        InactiveDate: false
    };

    const { settings, setDialog, setBadgeCount, onMessage, setSnackbar } = React.useContext(ApplicationContext);
    const [columnCache, setColumnCache] = useLocalStorage('alarmGrid', []);
    const [visibleCache, setVisibleCache] = useLocalStorage('alarmGrid.Visibility', defaultColVisibility);
    const [page, setPage] = React.useState(0);
    const [pageSize, setPageSize] = React.useState(defaultPageSize);
    const [showInactive, setShowInactive] = React.useState(false);

    const [gridRows, setGridRows] = React.useState([]);
    const [selectedRow, setSelectedRow] = React.useState({});
    const [selectedPriority, setSelectedPriority] = React.useState('');
    const [rowId, setRowId] = React.useState(0);
    const [openLogDialog, setOpenLogDialog] = React.useState(false);
    const [menuAnchor, setMenuAnchor] = React.useState(null);
    const [contextMenu, setContextMenu] = React.useState(null);
    const [showConfirmClear, setShowConfirmClear] = React.useState(false);
    const [filterModel, setFilterModel] = React.useState({ items: [], quickFilterExcludeHiddenColumns: true });

    const openMenu = Boolean(menuAnchor);
    const allowClear = settings.roles?.includes(UserRoles.OEM.label) || settings.roles?.includes(UserRoles.ADMIN.label);

    const [columVisibility, setColumnVisibility] = React.useState(Object.keys(visibleCache).length === 0 ? {
        InactiveDate: false
    } : { ...visibleCache, ...defaultColVisibility });

    const alarmCount = {
        Critical: 0,
        High: 0,
        Medium: 0,
        Low: 0,
        Active: 0,
        Total: 0
    };        
    const [alarmTotals, setAlarmTotals] = React.useState(alarmCount);

    const filter = React.useRef({
        searchString: '',
        fromDate: '',
        toDate: '',
        priority: '',
        topic: '',
        status: 'Active',
        source: '',
        roles: settings.roles
    });

    const [confirmParams, setConfirmParams] = React.useState({
        text: "",
        callBack: () => { }
    });

    const [serverData, setServerData] = React.useState({
        records: [],
        currentPage: 0,
        totalPages: 0,
        totalRecords: 0
    });

    // caching the column widths does not save the formatter functions
    const fixCachedCols = (cache) => {
        const activeDate = columnCache.find(c => c.field === 'AlarmDate');
        if (activeDate) {
            activeDate.valueFormatter = (date) => date ? dayjs(date).format('YYYY-MM-DD HH:mm:ss') : '';
        }

        const inactiveDate = columnCache.find(c => c.field === 'InactiveDate');
        if (inactiveDate) {
            inactiveDate.valueFormatter = (date) => date ? dayjs(date).format('YYYY-MM-DD HH:mm:ss') : '';
        }

        return cache;
    };

    const columns = columnCache.length === 0 ? [
        {
            field: 'Id', headerName: 'ID', flex: 0.15,
        },
        {
            field: 'AlarmDate', headerName: 'Alarm Date', flex: 0.3,
            valueFormatter: (date) => date ? dayjs(date).format('YYYY-MM-DD HH:mm:ss') : ''
        },
        {
            field: 'InactiveDate', headerName: 'Inactive Date', flex: 0.3,
            valueFormatter: (date) => date ? dayjs(date).format('YYYY-MM-DD HH:mm:ss') : ''
        },
        { field: 'Priority', headerName: 'Priority', flex: 0.25 },
        { field: 'Location', headerName: 'Location ID', flex: 0.25 },
        { field: 'LocationDesc', headerName: 'Location Name', flex: 0.45 },
        { field: 'AlarmType', headerName: 'Alarm Type', flex: 0.45 },
        {
            field: 'Message', headerName: 'Alarm Message', flex: 1,
            renderCell: (props) =>
                props.row.Url ? <Link href={`${props.row.Url}`} underline='hover' target='_blank'>{props.row.Message}</Link> : <>{props.row.Message}</>
        },
        { field: 'Source', headerName: 'Source', flex: 0.15 },
        { field: 'Status', headerName: 'Status', flex: 0.15 },
    ] : fixCachedCols(columnCache);

    const getTotals = (filter) => {
        getAlarmTotals(settings.token, JSON.stringify(filter),
            (response) => {
                if (!response.data.error) {
                    const totals = JSON.parse(response.data.result);
                    const counts = alarmCount;

                    totals.forEach((value, key) => {
                        counts[value.Name] = value.Count;
                    });

                    setAlarmTotals(counts);                    
                    const active = totals.find(item => item.Name === 'Active');
                    if (active) {
                        setBadgeCount(active['Count']);
                    }
                }
                else {
                    setDialog(response.data.errors[0], true);
                }
            },
            (error) => {
                setDialog(error.message, true);
            }
        );
    };

    const getFilteredData = (filter, page, pageSize) => {
        filter.roles = settings.roles;
        getTotals(filter);

        getFilteredAlarms(settings.token, JSON.stringify(filter), page, pageSize, 'ActiveDate', 1,
            (response) => {
                if (!response.data.error) {
                    setServerData({
                        records: JSON.parse(response.data.result),
                        currentPage: response.data.currentPage,
                        totalPages: response.data.totalPages,
                        totalRecords: response.data.totalRecords
                    });
                    setGridRows(JSON.parse(response.data.result));
                }
                else {
                    setDialog(response.data.errors[0], true);
                }
            },
            (error) => {
                setDialog(error.message, true);
            }
        );
    };

    const handlePageModelChanged = (event) => {
        setPage(event.page);
        setPageSize(event.pageSize);

        getFilteredData(filter.current, event.page, event.pageSize);
    };

    const handleClearFilter = () => {
        setFilterModel({ items: [], quickFilterExcludeHiddenColumns: true });
        setSelectedPriority('');
        setColumnVisibility({ ...visibleCache, ...defaultColVisibility });
        setShowInactive(false);

        getFilteredData(filter.current, page, pageSize);
    };

    const handlePriorityClick = (priority) => {
        if (selectedPriority === priority) {
            filter.current.priority = '';
            setSelectedPriority('');
        }
        else {
            filter.current.priority = priority === 'Total' ? '' : priority;
            setSelectedPriority(priority);
        }
    };

    const handleOpacity = (id) => {
        return selectedPriority === '' || selectedPriority === id ? 1 : 0.2;
    };

    const handleFilter = () => {
        getFilteredData(filter.current, page, pageSize);
    };

    const handleRowSelected = (ids) => {
        if (ids.length > 0) {
            setSelectedRow(gridRows.find((row) => row.Id === ids[0]));
        }
    };

    const handleCellDoubleClick = (params, event) => {
        if (allowClear) {
            setOpenLogDialog(true);
        }
    };

    const handleColumnWidthChange = (params, event, details) => {
        const col = columns.find(c => c.headerName === params.colDef.headerName);
        if (col) {
            delete col.flex;

            col.width = params.width;
            setColumnCache(columns);
        }
    };

    const handleColumnVisibilityChange = (model) => {
        setColumnVisibility(model);
        setVisibleCache(model);
    };

    const handleClearClick = (event) => {
        setMenuAnchor(event.currentTarget);
    };

    const handleClearItemClick = (event) => {
        setContextMenu(null);

        setConfirmParams({
            text: "Are you sure you want to clear this alarm/notification?",
            callBack: doClearItem
        });

        setShowConfirmClear(true);
    };

    const handleMenuClose = () => {
        setMenuAnchor(null);
    };

    const handleContextMenu = (event, params) => {
        event.preventDefault();

        setRowId(Number(event.currentTarget.getAttribute('data-id')));
        setContextMenu(
            contextMenu === null
                ? { mouseX: event.clientX - 2, mouseY: event.clientY - 4 }
                : null,
        );
    };

    const doClearItem = () => {
        setShowConfirmClear(false);

        clearAlarm(settings.token, rowId, settings.userName,
            (response) => {
                if (!response.data.error) {
                    getFilteredData(filter.current, page, pageSize);
                    setSnackbar('Item cleared successfully.');

                }
                else {
                    setDialog(response.data.errors[0], true);
                }
            },
            (error) => {
                setDialog(error.message, true);
            }
        );
    };

    const handleClearAlarms = () => {
        setConfirmParams({
            text: "Are you sure you want to clear all current alarms?",
            callBack: () => doClearAll(1)
        });

        setMenuAnchor(null);
        setShowConfirmClear(true);
    };

    const handleClearNotifications = () => {
        setConfirmParams({
            text: "Are you sure you want to clear all current notifications?",
            callBack: () => doClearAll(0)
        });

        setMenuAnchor(null);
        setShowConfirmClear(true);
    };

    const doClearAll = (isAlarms) => {
        setShowConfirmClear(false);

        clearAllAlarms(settings.token, isAlarms, settings.userName,
            (response) => {
                if (!response.data.error) {
                    getFilteredData(filter.current, page, pageSize);
                    setSnackbar(isAlarms ? 'Alarms cleared successfully.' : 'Notifications cleared successfully.');
                }
                else {
                    setDialog(response.data.errors[0], true);
                }
            },
            (error) => {
                setDialog(error.message, true);
            }
        );
    };

    const handleFilterModelChange = (model) => {
        setFilterModel(model);
    };

    const handleSwitchChange = (event) => {
        filter.current.status = !event.target.checked ? '' : 'Active';
        setShowInactive(!event.target.checked);

        if (!event.target.checked) {
            setColumnVisibility({ ...visibleCache, ...{ InactiveDate: true } });
        }
        else {
            setColumnVisibility({ ...visibleCache, ...defaultColVisibility });
        }
    };

    React.useEffect(() => {
        if (Object.keys(settings).length > 0) {
            getFilteredData(filter.current, page, pageSize);
        }
    }, [settings, selectedPriority, showInactive]);

    React.useEffect(() => {
        if (Object.keys(onMessage).length > 0) {
            getFilteredData(filter.current, page, pageSize);
        }
    }, [onMessage]);

    return (
        <>
            <Box sx={{ display: 'flex', mb: 2 }}>
                <Box sx={{ width: "50%" }}>
                    <Typography sx={{ marginLeft: "5px", fontWeight: '400' }} style={{ fontSize: "25px" }}>Alarm Viewer</Typography>
                    <Typography sx={{ marginLeft: "5px", fontWeight: '300' }} style={{ fontSize: "15px" }}>Review active and historical alarms.</Typography>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: "50%", mb: 4 }}>
                    <Button variant="contained" color="success"
                        style={{ width: "120px", marginLeft: "7px", display: allowClear ? 'flex' : 'none' }}
                        endIcon={<KeyboardArrowDownIcon />}
                        onClick={handleClearClick}
                        disableElevation
                        aria-haspopup="true"
                    >
                        Clear
                    </Button>
                    <Menu
                        open={openMenu}
                        onClose={handleMenuClose}
                        elevation={0}
                        anchorEl={menuAnchor}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        sx={{
                            marginTop: 1
                        }}
                        slotProps={{
                            paper: {
                                sx: {
                                    minWidth: 150,
                                    border: 1,
                                    borderColor: 'grey'
                                },
                            }
                        }}
                    >
                        <MenuItem disableRipple sx={{ justifyContent: 'flex-end' }} onClick={handleClearAlarms} >
                            Current alarms
                        </MenuItem>
                        <MenuItem disableRipple sx={{ justifyContent: 'flex-end' }} onClick={handleClearNotifications}>
                            Current notifications
                        </MenuItem>
                    </Menu>
                </Box>
            </Box>
            <Divider sx={{ mb: 4 }} />
            <Container maxWidth='lg' sx={{ display: 'flex', gap: 2 }}>
                {/* <Grid item sx={{ flexGrow: 1, display: showInactive ? 'display' : 'none' }}>
                    <Card variant="outlined" sx={{ height: 100, backgroundColor: 'darkgray', opacity: () => handleOpacity('Total'), maxWidth: 215 }}>
                        <Tooltip title={'Total Alarms'} placement='top'>
                            <CardActionArea>
                                <CardContent>
                                    <Box sx={{ display: 'flex' }}>
                                        <Typography variant="body1" sx={{ fontWeight: 400, color: 'white', width: '80%' }}>Total</Typography>
                                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '20%' }} >
                                            <NotificationsIcon sx={{ color: 'white' }} />
                                        </Box>
                                    </Box>
                                    <Typography variant="body1" sx={{ fontWeight: 600, color: 'white', fontSize: 30, mt: 1 }}>{alarmTotals.Total.toLocaleString()}</Typography>
                                </CardContent>
                            </CardActionArea>
                        </Tooltip>
                    </Card>
                </Grid> */}
                <Grid item sx={{ flexGrow: 1 }}>
                    <Card variant="outlined" sx={{ height: 100, backgroundColor: '#cf2d2e', opacity: () => handleOpacity('Critical') }} >
                        <Tooltip title={selectedPriority === 'Critical' ? 'Clear filter' : 'Show Critical priority alarms'} placement='top'>
                            <CardActionArea onClick={(e) => handlePriorityClick('Critical')}>
                                <CardContent>
                                    <Box sx={{ display: 'flex' }}>
                                        <Typography variant="body1" sx={{ fontWeight: 400, color: 'white', width: '50%' }}>Critical</Typography>
                                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '50%' }} >
                                            <NotificationsIcon sx={{ color: 'white' }} />
                                        </Box>
                                    </Box>
                                    <Typography variant="body1" sx={{ fontWeight: 600, color: 'white', fontSize: 30, mt: 1 }}>{alarmTotals.Critical.toLocaleString()}</Typography>
                                </CardContent>
                            </CardActionArea>
                        </Tooltip>
                    </Card>
                </Grid>
                <Grid item sx={{ flexGrow: 1 }}>
                    <Card variant="outlined" sx={{ height: 100, backgroundColor: '#ff9a03', opacity: () => handleOpacity('High') }} >
                        <Tooltip title={selectedPriority === 'High' ? 'Clear filter' : 'Show High priority alarms'} placement='top'>
                            <CardActionArea onClick={(e) => handlePriorityClick('High')}>
                                <CardContent>
                                    <Box sx={{ display: 'flex' }}>
                                        <Typography variant="body1" sx={{ fontWeight: 400, color: 'white', width: '50%' }}>High</Typography>
                                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '50%' }} >
                                            <NotificationsIcon sx={{ color: 'white' }} />
                                        </Box>
                                    </Box>
                                    <Typography variant="body1" sx={{ fontWeight: 600, color: 'white', fontSize: 30, mt: 1 }}>{alarmTotals.High.toLocaleString()}</Typography>
                                </CardContent>
                            </CardActionArea>
                        </Tooltip>
                    </Card>
                </Grid>
                <Grid item sx={{ flexGrow: 1 }}>
                    <Card variant="outlined" sx={{ height: 100, backgroundColor: '#1aa1fb', opacity: () => handleOpacity('Medium') }} >
                        <Tooltip title={selectedPriority === 'Medium' ? 'Clear filter' : 'Show Medium priority alarms'} placement='top'>
                            <CardActionArea onClick={(e) => handlePriorityClick('Medium')}>
                                <CardContent>
                                    <Box sx={{ display: 'flex' }}>
                                        <Typography variant="body1" sx={{ fontWeight: 400, color: 'white', width: '50%' }}>Medium</Typography>
                                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '50%' }} >
                                            <NotificationsIcon sx={{ color: 'white' }} />
                                        </Box>
                                    </Box>
                                    <Typography variant="body1" sx={{ fontWeight: 600, color: 'white', fontSize: 30, mt: 1 }}>{alarmTotals.Medium.toLocaleString()}</Typography>
                                </CardContent>
                            </CardActionArea>
                        </Tooltip>
                    </Card>
                </Grid>
                <Grid item sx={{ flexGrow: 1 }}>
                    <Card variant="outlined" sx={{ height: 100, backgroundColor: '#bc93f0', opacity: () => handleOpacity('Low') }} >
                        <Tooltip title={selectedPriority === 'Low' ? 'Clear filter' : 'Show Low priority alarms'} placement='top'>
                            <CardActionArea onClick={(e) => handlePriorityClick('Low')}>
                                <CardContent>
                                    <Box sx={{ display: 'flex' }}>
                                        <Typography variant="body1" sx={{ fontWeight: 400, color: 'white', width: '50%' }}>Low</Typography>
                                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '50%' }} >
                                            <NotificationsIcon sx={{ color: 'white' }} />
                                        </Box>
                                    </Box>
                                    <Typography variant="body1" sx={{ fontWeight: 600, color: 'white', fontSize: 30, mt: 1 }}>{alarmTotals.Low.toLocaleString()}</Typography>
                                </CardContent>
                            </CardActionArea>
                        </Tooltip>
                    </Card>
                </Grid>
            </Container>


            <Grid container gap={2} sx={{ mt: 2 }}>
                <Grid item gap={2} sx={{ display: 'flex', width: '100%' }}>
                    <CustomFilter filter={filter}
                        onFilter={handleFilter}
                        onClearFilter={handleClearFilter}
                    />
                </Grid>
                <Grid item sx={{ width: '100%' }}>
                    <Box sx={{ display: 'flex', flex: 1 }}>
                        <Box item sx={{ width: '50%', mt: 1 }}>
                            <Typography color={showInactive ? 'error' : 'primary'} 
                                fontWeight={showInactive ? 'bold' : 'normal'}> 
                                    {
                                        showInactive ? 
                                            ( selectedPriority ? `Showing ${selectedPriority} Active & Inactive Alarms` : `Showing Active & Inactive Alarms (${alarmTotals.Total.toLocaleString()} in total)`) : 
                                            ( selectedPriority ? `Showing ${selectedPriority} Active Alarms` : `Showing Active Alarms (${alarmTotals.Active.toLocaleString()} in total)`)
                                    }
                            </Typography>
                        </Box>
                        <Box sx={{ width: '50%', display: 'flex', justifyContent: 'flex-end' }}>
                            <Stack direction='row' spacing={1} sx={{ alignItems: 'center', mr: 1 }} >
                                <Typography fontWeight={showInactive ? 'bold' : 'regular'}>
                                    Show all
                                </Typography>
                                <Switch checked={!showInactive} color={!showInactive ? 'primary' : 'error'} onChange={handleSwitchChange} />
                                <Typography fontWeight={!showInactive ? 'bold' : 'regular'}>
                                    or Active Only
                                </Typography>
                            </Stack>
                        </Box>
                    </Box>
                </Grid>

                <Grid item sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                    <CustomDataGrid
                        columns={columns}
                        paginationMode='server'

                        rows={gridRows}
                        rowCount={serverData.totalRecords}
                        getRowId={(row) => row.Id}

                        pagination
                        pageSizeOptions={[10, 20, 30, 50]}
                        onPaginationModelChange={handlePageModelChanged}

                        initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: defaultPageSize,
                                },
                            },
                            filter: {
                                filterModel: {
                                    items: [],
                                    quickFilterExcludeHiddenColumns: true
                                }
                            }
                        }}

                        getRowClassName={(params) => {
                            const active = params.row.Status === 'Active';
                            if (active) {
                                return params.row.Notification ?
                                    'notification' :
                                    params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : '';
                            }
                            else {
                                return 'inactive';
                            }
                        }}

                        slotProps={{
                            row: {
                                onContextMenu: allowClear ? handleContextMenu : null,
                                style: { cursor: 'context-menu' },
                            },
                            pagination: {
                                showFirstButton: true,
                                showLastButton: true
                            }
                        }}

                        columnVisibilityModel={columVisibility}
                        onColumnVisibilityModelChange={handleColumnVisibilityChange}
                        onColumnWidthChange={handleColumnWidthChange}
                        onRowSelectionModelChange={handleRowSelected}
                        onCellDoubleClick={handleCellDoubleClick}
                        onFilterModelChange={handleFilterModelChange}

                        disableColumnFilter
                        filterModel={filterModel}

                        autoHeight
                    />
                </Grid>
                <Dialog
                    open={openLogDialog}
                    maxWidth='lg'
                    fullWidth={true}
                    PaperProps={{
                        sx: {
                            height: '50vh',
                        }
                    }}
                    onKeyDown={(e) => {
                        if (e.key === 'Escape') {
                            setOpenLogDialog(false);
                        }
                    }}
                >
                    <DialogTitle>Message Log</DialogTitle>
                    <DialogContent>
                        <AppLog refIds={[selectedRow.ActiveRefId, selectedRow.InactiveRefId]} />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setOpenLogDialog(false)}>Close</Button>
                    </DialogActions>
                </Dialog>
                <Menu
                    open={contextMenu !== null}
                    onClose={() => setContextMenu(null)}
                    anchorReference="anchorPosition"
                    anchorPosition={
                        contextMenu !== null
                            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                            : undefined
                    }
                >
                    <MenuItem onClick={handleClearItemClick}>
                        <NotInterestedIcon sx={{ mr: 2 }} />Clear
                    </MenuItem>
                </Menu>
                <ConfirmDialog open={showConfirmClear} title={"Confirm Clear"}
                    content={confirmParams.text}
                    detail="<strong>Note:</strong> This cannot be undone. <br /> <br />"
                    onNoClick={() => setShowConfirmClear(false)}
                    onYesClick={confirmParams.callBack}
                    yesNo
                />
            </Grid>
        </>
    );
}

export default AlarmViewer;