import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@mui/material";
import PropTypes from 'prop-types';

ConfirmDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    detail: PropTypes.string,
    onCancelClick: PropTypes.func,
    onNoClick: PropTypes.func.isRequired,
    onYesClick: PropTypes.func.isRequired,
    yesNo: PropTypes.bool,
    yesNoCancel: PropTypes.bool
}

function ConfirmDialog(props) {

    return (
        <Dialog
            open={props.open}
            maxWidth="sm"
            fullWidth={true}
            keepMounted
        >
            <DialogTitle>{props.title}</DialogTitle>
            <DialogContent dividers>
                {props.content} <br /> <br />
                <div dangerouslySetInnerHTML={{__html: props.detail}} />                
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onCancelClick} sx={{ display: props.yesNoCancel ? 'flex' : 'none'}}>Cancel</Button>
                <Button onClick={props.onNoClick}>No</Button>
                <Button onClick={props.onYesClick} variant="contained">Yes</Button>
            </DialogActions>
        </Dialog>
    )
};

export default ConfirmDialog;