import { useContext, useEffect, useState } from 'react';
import {
    Button, Box, Stack,
    Dialog, DialogActions, DialogContent,
    DialogTitle, Menu, MenuItem,
    Typography,
    Divider,
    Container
} from '@mui/material';

import ApplicationContext from '../contexts/application';
import CommonParams from './components/CommonParams';

import { CustomDataGrid } from '../components/CustomDataGrid';
import { useLocalStorage } from '../libs/useLocalStorage';
import { useNavigate } from "react-router";


import ApiIn from './components/ApiIn';
import ApiOut from './components/ApiOut';
import TcpIn from './components/TcpIn';
import TcpOut from './components/TcpOut';
import HL7In from './components/HL7In';
import MqttIn from './components/MqttIn';
import MqttOut from './components/MqttOut';

import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import StopCircleIcon from '@mui/icons-material/StopCircle';
import ConfirmDialog from '../components/ConfirmDialog';

import {
    getIntegrations, postIntegrations, startIntegration,
    stopIntegration, deleteIntegration
} from '../libs/apiCalls';
import { UserRoles } from '../libs/userRoles';

function IntegrationSources() {
    const { settings, setDialog } = useContext(ApplicationContext);
    const navigate = useNavigate();

    const [gridData, setGridData] = useState([]);
    const [protocol, setProtocol] = useState("");
    const [direction, setDirection] = useState("");
    const [paramComponent, setParamComponent] = useState(null);
    const [openParams, setOpenParams] = useState(false);
    const [reloadGrid, setReloadGrid] = useState(false);
    const [currentRow, setCurrentRow] = useState({});
    const [dialogTitle, setDialogTitle] = useState("");
    const [cachedRow, setCachedRow] = useLocalStorage({});
    const [rowId, setRowId] = useState(0);
    const [contextMenu, setContextMenu] = useState(null);

    const [showConfirmDelete, setShowConfirmDelete] = useState(false);
    const [showConfirmStop, setShowConfirmStop] = useState(false);

    const columns = [
        { field: 'integrationId', headerName: 'ID', width: 100 },
        { field: 'name', headerName: 'Name', width: 250 },
        { field: 'description', headerName: 'Description', width: 450 },
        { field: 'direction', headerName: 'In/Out', width: 110 },
        { field: 'protocol', headerName: 'Protocol', width: 120 },
        { field: 'port', headerName: 'Port', width: 150 },
        { field: 'topic', headerName: 'Topic', minWidth: 150, flex: 1 },
        {
            field: 'status',
            headerName: "",
            width: 80,
            renderCell: (props) => {
                return (
                    // <Stack sx={{ width: 100, flexGrow: 1 }}>
                    //     <FiberManualRecordIcon
                    //         sx={{
                    //             color: props.value ? '#4caf50' : '#d9182e',
                    //             m: 'auto'
                    //         }}
                    //     />
                    // </Stack>
                    <></>
                );
            }
        }
    ];

    const handleNew = () => {
        setCurrentRow({});
        setCachedRow({});
        setDialogTitle("Add Integration");

        setOpenParams(true);
    };

    const handleClose = () => {
        setProtocol("");
        setDirection("");

        if (dialogTitle.includes("Edit")) {
            // reset the row data, if cancel was pressed
            setCurrentRow(cachedRow);
        }

        setOpenParams(false);
    };

    const handleProtocol = (event) => {
        setProtocol(event.target.value);

        if (Object.keys(currentRow).length > 0) {
            currentRow.protocol = event.target.value;
        }
    };

    const handleDirection = (event) => {
        setDirection(event.target.value);

        if (Object.keys(currentRow).length > 0) {
            currentRow.direction = event.target.value;
        }
    }

    const handleRowSelected = (ids) => {
        const selectedRows = ids.map((id) => gridData.find((row) => row.integrationId === id));

        // clone the row
        if (selectedRows[0]) {
            var row = JSON.stringify(selectedRows[0]);
            setCurrentRow(JSON.parse(row));

            // cache the row
            setCachedRow(JSON.parse(row));
        }
    };

    const handleEdit = () => {
        if (Object.keys(currentRow).length > 0) {
            setDialogTitle("Edit Integration");

            setProtocol(currentRow.protocol);
            setDirection(currentRow.direction);
            setOpenParams(true);
        }
    };

    const handleDelete = () => {
        if (Object.keys(currentRow).length > 0) {
            setShowConfirmDelete(true);
        }
    };

    const handleChange = (event) => {
        if (Object.keys(currentRow).length > 0) {
            currentRow[event.target.name] = event.target.value;
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const json = dialogTitle.includes("Edit") ? currentRow : Object.fromEntries(data.entries());

        if (dialogTitle.includes("Edit")) {
            currentRow.updatedBy = settings.userName;
        } 
        else {
            currentRow.createdBy = settings.userName;
        }

        postIntegrations(settings.token, json,
            () => {
                setReloadGrid(!reloadGrid);
            },
            (error) => {
                setDialog(error.message, true);
            }
        );

        setCachedRow({});
        setCachedRow({});
        handleClose();
    };

    const handleContextMenu = (event) => {
        event.preventDefault();
        setRowId(Number(event.currentTarget.getAttribute('data-id')));
        setContextMenu(
            contextMenu === null
                ? { mouseX: event.clientX - 2, mouseY: event.clientY - 4 }
                : null,
        );
    };

    const handleStart = () => {
        startIntegration(settings.token, rowId,
            () => {
                setReloadGrid(!reloadGrid);
            },
            (error) => {
                setDialog(error.message, true);
            }
        );

        setContextMenu(null);
    }

    const handleStop = () => {
        if (Object.keys(currentRow).length > 0) {
            setContextMenu(null);
            setShowConfirmStop(true);
        }
    }

    const handleConfirmStop = () => {
        setShowConfirmStop(false);

        stopIntegration(settings.token, rowId,
            () => {
                setReloadGrid(!reloadGrid);
            },
            (error) => {
                setDialog(error.message, true);
            }
        );
    }

    const handleConfirmDelete = () => {
        setShowConfirmDelete(false);

        deleteIntegration(settings.token, currentRow,
            () => {
                setReloadGrid(!reloadGrid);
                setCurrentRow({});
            },
            (error) => {
                setDialog(error.message, true);
            }
        );
    }

    const handleCellDoubleClick = (params, event) => {
        handleEdit();
    };

    useEffect(() => {
        if (Object.keys(settings).length > 0) {
            // TODO: create another protected control
            if (!settings.roles?.includes(UserRoles.OEM.label)) {
                navigate('/');
            }

            getIntegrations(settings.token,
                (response) => {
                    if (!response.data.error) {
                        setGridData(JSON.parse(response.data.result));
                    }
                    else {
                        setDialog(response.data.errors[0], true);
                    }
                },
                (error) => {
                    setDialog(error.message, true);
                }
            );

            // getIntegrationStatus(settings.token, 
            //     (response) => {
            //         if (!response.data.error) {
            //             const stats = JSON.parse(response.data.result);
            //             const map = new Map(Object.entries(stats));

            //             gridData.forEach((row) => {
            //                 console.log(row);
            //             });
                        
            //         }         
            //         else {
            //             setDialog(response.data.errors[0], true);
            //         }                     
            //     },
            //     (error) => {
            //         setDialog(error.message, true);
            //     }
            // );
        }
    }, [settings, reloadGrid]);

    useEffect(() => {
        if (protocol === "API") {
            if (direction === "IN") {
                setParamComponent(<ApiIn row={currentRow} onChange={handleChange} />);
            }
            else if (direction === "OUT") {
                setParamComponent(<ApiOut row={currentRow} onChange={handleChange} />);
            }
        }
        else if (protocol === "TCP" || protocol === "UDP") {
            if (direction === "IN") {
                setParamComponent(<TcpIn row={currentRow} onChange={handleChange} />);
            }
            else if (direction === "OUT") {
                setParamComponent(<TcpOut row={currentRow} onChange={handleChange} />);
            }
        }
        else if (protocol === "HL7" && direction === "IN") {
            setParamComponent(<HL7In row={currentRow} onChange={handleChange} />);
        }
        else if (protocol === "MQTT") {
            if (direction === "IN") {
                setParamComponent(<MqttIn row={currentRow} onChange={handleChange} />);
            }
            else if (direction === "OUT") {
                setParamComponent(<MqttOut row={currentRow} onChange={handleChange} />);
            }
        }
        else {
            setParamComponent(null);
        }
    },
        // eslint-disable-next-line
        [protocol, direction]);


    return (
        <>
            <Box sx={{ display: 'flex', mb: 2 }}>
                <Box sx={{ width: "50%" }}>
                    <Typography sx={{ marginLeft: "5px", fontWeight: '400' }} style={{ fontSize: "25px" }}>Integration Sources</Typography>
                    <Typography sx={{ marginLeft: "5px", fontWeight: '300' }} style={{ fontSize: "15px" }}>Agent configurations. System integration ins and outs.</Typography>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: "50%", mb: 4 }}>
                    <Button variant="outlined" color="error" style={{ width: "120px" }} onClick={handleDelete}>Delete</Button>
                    <Button variant="outlined" style={{ width: "120px", marginLeft: "7px" }} onClick={handleEdit}>Edit</Button>
                    <Button variant="contained" color="success" style={{ width: "120px", marginLeft: "7px" }} onClick={handleNew}>Add</Button>
                </Box>
            </Box>
            <Divider sx={{ mb: 4 }} />
            <Container maxWidth="xl">

                <Box sx={{ height: '100%', width: '100%' }}>
                    <Stack direction="column" spacing={2}>
                        <CustomDataGrid
                            rows={gridData}
                            columns={columns}
                            initialState={{
                                pagination: {
                                    paginationModel: {
                                        pageSize: 15,
                                    },
                                },
                            }}
                            pageSizeOptions={[15]}
                            disableMultipleRowSelection
                            getRowId={(row) => row.integrationId}
                            
                            onRowSelectionModelChange={handleRowSelected}
                            onCellDoubleClick={handleCellDoubleClick}

                            getRowClassName={(params) =>
                                params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                            }
                            slotProps={{
                                row: {
                                    onContextMenu: handleContextMenu,
                                    style: { cursor: 'context-menu' },
                                }
                            }}
                        />
                    </Stack>

                    <Dialog
                        open={openParams}
                        onClose={handleClose}
                        maxWidth="md"
                        fullWidth={true}
                        PaperProps={{
                            component: "form",
                            onSubmit: (handleSubmit)
                        }}
                    >
                        <DialogTitle>{dialogTitle}</DialogTitle>
                        <DialogContent>
                            <CommonParams row={currentRow} onChange={handleChange} onProtocolChange={handleProtocol}
                                onDirectionChange={handleDirection} />

                            {paramComponent}

                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose}>Cancel</Button>
                            <Button type="submit" variant="contained">Save</Button>
                        </DialogActions>
                    </Dialog>
                    <Menu
                        open={contextMenu !== null}
                        onClose={() => setContextMenu(null)}
                        anchorReference="anchorPosition"
                        anchorPosition={
                            contextMenu !== null
                                ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                                : undefined
                        }
                        slotProps={{
                            root: {
                                onContextMenu: (e) => {
                                    e.preventDefault();
                                    handleClose();
                                },
                            },
                        }}
                    >
                        <MenuItem onClick={handleStart}><PlayCircleIcon sx={{ mr: 2 }} />Start</MenuItem>
                        <MenuItem onClick={handleStop}><StopCircleIcon sx={{ mr: 2 }} />Stop</MenuItem>
                    </Menu>

                    <ConfirmDialog open={showConfirmDelete} title={"Confirm Delete"}
                        content={"Are you sure you want to remove this integration? (Note: this cannot be undone)"}
                        onNoClick={() => setShowConfirmDelete(false)}
                        onYesClick={handleConfirmDelete}
                        yesNo
                    />

                    <ConfirmDialog open={showConfirmStop} title={"Confirm Stop"}
                        content={"Are you sure you want to stop this integration? (Note: any workflows using this may be affected.)"}
                        onNoClick={() => setShowConfirmStop(false)}
                        onYesClick={handleConfirmStop}
                        yesNo
                    />
                </Box>
            </Container>
        </>

    )
}

export default IntegrationSources;