import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@mui/material";
import PropTypes from 'prop-types';


CustomDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    onOkClick: PropTypes.func.isRequired,
    isError: PropTypes.bool.isRequired
}

function CustomDialog(props) {

    return (
        <Dialog
            open={props.open}
            maxWidth="md"
            fullWidth={true}
            keepMounted
        >
            <DialogTitle>{props.title}</DialogTitle>
            <DialogContent dividers>
                <div dangerouslySetInnerHTML={{__html: props.content}} />                
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={props.onOkClick} variant="contained" color={props.isError ? "error" : "success"}>OK</Button>
            </DialogActions>
        </Dialog>
    )
};

export default CustomDialog;