import React from "react";

import {
    Box, Button, Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider, Grid, LinearProgress, Menu, MenuItem,
    TextField,
    Typography
} from "@mui/material";

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ApplicationContext from "../contexts/application";

import { CustomDataGrid } from "../components/CustomDataGrid";
import { useNavigate } from "react-router";
import { deleteLocation, getFilteredLocations, importLocationFile, saveLocation } from "../libs/apiCalls";
import ConfirmDialog from "../components/ConfirmDialog";
import CustomFilter from "../components/CustomFilter";

function Locations() {
    let defaultPageSize = 20;

    const { settings, setDialog, setSnackbar } = React.useContext(ApplicationContext);

    const [overwrite, setOverwrite] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [anchor, setAnchor] = React.useState(null);
    const [page, setPage] = React.useState(0);
    const [pageSize, setPageSize] = React.useState(defaultPageSize);
    const [openDialog, setOpenDialog] = React.useState(false);
    const [reload, setReload] = React.useState(false);
    const [gridRows, setGridRows] = React.useState([]);
    const [selectedRow, setSelectedRow] = React.useState({});
    const [showConfirmClear, setShowConfirmClear] = React.useState(false);
    const [showConfirmDelete, setShowConfirmDelete] = React.useState(false);
    const [gridSelection, setGridSelection] = React.useState([]);

    const open = Boolean(anchor);
    const importFile = React.useRef(null);

    const columns = [
        { field: 'id', headerName: 'ID', flex: 0.1 },
        { field: 'location', headerName: 'Location', flex: 0.3 },
        { field: 'description', headerName: 'Description', flex: 1 }
    ];

    const [serverData, setServerData] = React.useState({
        records: [],
        currentPage: 0,
        totalPages: 0,
        totalRecords: 0
    });

    const filter = React.useRef({
        searchString: '',
        fromDate: '',
        toDate: '',
        priority: '',
        topic: '',
        status: ''
    });

    const [confirmParams, setConfirmParams] = React.useState({
        text: "",
        callBack: null
    });

    const newRecord = {
        id: '',
        location: '',
        description: '',
        createdBy: settings.userName
    };

    const getData = (page, pageSize) => {
        getFilteredLocations(settings.token, filter.current, page, pageSize, 'Location', 0,
            (response) => {
                if (!response.data.error) {
                    setServerData({
                        records: JSON.parse(response.data.result),
                        currentPage: response.data.currentPage,
                        totalPages: response.data.totalPages,
                        totalRecords: response.data.totalRecords
                    });

                    setGridRows(JSON.parse(response.data.result));
                }
                else {
                    setDialog(response.data.errors[0], true);
                }

                setLoading(false);
            },
            (error) => {
                setDialog(error.message, true);
                setLoading(false);
            }
        );
    }

    const handlePageModelChanged = (event) => {
        setPage(event.page);
        setPageSize(event.pageSize);

        getData(event.page, event.pageSize);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        setOpenDialog(false);
        setLoading(true);

        if (Object.keys(selectedRow).length > 0) {
            saveLocation(settings.token, selectedRow,
                (response) => {
                    if (!response.data.error) {
                        setSnackbar("Record added/updated successfully.");
                    }
                    else {
                        setDialog(response.data.errors[0], true);
                    }
                    setLoading(false);
                    setReload(!reload);

                },
                (error) => {
                    setDialog(error.message, true);
                    setLoading(false);
                    setReload(!reload);

                }
            );
        };
    }

    const handleAddClick = (event) => {
        setAnchor(event.currentTarget);
    }

    const handleMenuClose = (event) => {
        setAnchor(null);
    };

    const handleNew = () => {
        setAnchor(null);
        setSelectedRow(newRecord);
        setOpenDialog(true);
    };

    const handleEdit = () => {
        if (Object.keys(selectedRow).length > 0) {
            setOpenDialog(true);
        }
    }

    const handleDelete = () => {
        if (selectedRow) {
            setShowConfirmDelete(true);
        }
    }

    const doDelete = () => {
        setShowConfirmDelete(false);
        setLoading(true);

        deleteLocation(settings.token, selectedRow,
            (response) => {
                if (!response.data.error) {
                    setSnackbar("Location deleted.");
                    setReload(!reload);
                }
                else {
                    setDialog(response.data.errors[0], true);
                }

                setLoading(false);
                setReload(!reload);
            },
            (error) => {
                setDialog(error.message, true);
                setLoading(false);
                setReload(!reload);

            }
        );
    };

    const handleImportFile = (event) => {
        setAnchor(null);

        setShowConfirmClear(true);
        setConfirmParams({
            text: "Are you sure you want to import locations from a file?",
            callBack: handleFileSelect
        });
    };

    const handleFileSelect = (clear) => {
        setShowConfirmClear(false);
        setOverwrite(clear);

        importFile.current.click();
    }

    const handleFileChange = (event) => {
        const formData = new FormData();
        formData.append("file", event.target.files[0]);
        setLoading(true);

        importLocationFile(settings.token, formData, overwrite, settings.userName,
            (response) => {
                if (!response.data.error) {
                    let messageContent = '';

                    if (response.data.result.length > 0) {
                        let result = JSON.parse(response.data.result);
                        result.map((item, index) => {
                            if (index === 0) {
                                messageContent = '<strong>' + item + '</strong><br /><br />';
                            } else {

                                messageContent += item + (index === 0 ? '<br /><br />' : '<br />');
                            }
                        });
                    }

                    setDialog(messageContent);
                }
                else {
                    setDialog(response.data.errors[0], true);
                }

                setLoading(false);
                importFile.current.value = '';
            },
            (error) => {
                setDialog(error.message, true);

                setLoading(false);
                importFile.current.value = '';

            }
        );
    }

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setReload(!reload);
        setSelectedRow({});

    };

    const handleRowSelected = (ids) => {
        setGridSelection(ids);

        if (ids.length > 0) {
            setSelectedRow(gridRows.find((row) => row.id === ids[0]));
        }
    };

    const handleCellDoubleClick = (params, event) => {
        if (selectedRow) {
            setOpenDialog(true);
        }
    };

    const handleOnChange = (event) => {
        if (Object.keys(selectedRow).length > 0) {
            selectedRow[event.target.name] = event.target.value;
        }
    };

    const handleFilter = () => {
        getData(page, pageSize);
    };

    const handleClearFilter = () => {
        getData(page, pageSize);
    };

    React.useEffect(() => {
        if (Object.keys(settings).length > 0) {
            getData(page, pageSize);
        }
    }, [settings, reload]);

    return (
        <>
            <form onSubmit={handleSubmit}>
                <Box sx={{ display: 'flex', mb: 2 }}>
                    <Box sx={{ width: "50%" }}>
                        <Typography sx={{ marginLeft: '5px', fontWeight: '400', fontSize: '25px' }}>Locations</Typography>
                        <Typography sx={{ marginLeft: '5px', fontWeight: '300', fontSize: '15px' }}>Provide descriptions for room identifiers.</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: "50%", mb: 4 }}>
                        <Button variant="outlined" color="error"
                            style={{ width: "120px" }}
                            onClick={handleDelete}
                        >
                            Delete
                        </Button>
                        <Button variant="outlined" style={{ width: "120px", marginLeft: "7px" }} onClick={handleEdit}>Edit</Button>
                        <Button variant="contained" color="success"
                            style={{ width: "120px", marginLeft: "7px" }}
                            endIcon={<KeyboardArrowDownIcon />}
                            onClick={handleAddClick}
                            disableElevation
                            aria-haspopup="true"
                        >
                            Add
                        </Button>
                        <Menu
                            open={open}
                            onClose={handleMenuClose}
                            elevation={0}
                            anchorEl={anchor}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            sx={{
                                marginTop: 1
                            }}
                            slotProps={{
                                paper: {
                                    sx: {
                                        minWidth: 150,
                                        border: 1,
                                        borderColor: 'grey'
                                    },
                                }
                            }}
                        >
                            <MenuItem onClick={handleNew} disableRipple sx={{ justifyContent: 'flex-end' }}>
                                Add new item
                            </MenuItem>
                            <MenuItem onClick={handleImportFile} disableRipple sx={{ justifyContent: 'flex-end' }}>
                                Import from file
                            </MenuItem>
                        </Menu>
                    </Box>
                    <input
                        type='file'
                        id='import-file'
                        ref={importFile}
                        style={{ display: 'none' }}
                        accept='.csv,.txt'
                        onChange={handleFileChange}
                    />
                </Box>
                <Divider sx={{ mb: 4 }} />
                <Container maxWidth="xl">
                    <Grid item gap={2} sx={{ display: 'flex', width: '100%' }}>
                        <CustomFilter filter={filter}
                            onFilter={() => getData(page, pageSize)}
                            onClearFilter={() => getData(page, pageSize)}
                            searchOnly
                        />
                    </Grid>
                    <Grid item sx={{ display: 'flex', width: '100%', mt: 2 }} >
                        <LinearProgress sx={{ width: '100%', display: loading ? 'flex' : 'none' }} />
                        <CustomDataGrid
                            columns={columns}
                            paginationMode='server'
                            rows={gridRows}
                            rowCount={serverData.totalRecords}

                            initialState={{
                                pagination: {
                                    paginationModel: {
                                        pageSize: defaultPageSize,
                                    },
                                },
                            }}

                            pagination
                            pageSizeOptions={[5, 10, 20, 50]}
                            onPaginationModelChange={handlePageModelChanged}
                            onRowSelectionModelChange={handleRowSelected}
                            onCellDoubleClick={handleCellDoubleClick}
                            rowSelectionModel={gridSelection}

                            getRowId={(row) => row.id}

                            getRowClassName={(params) =>
                                params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                            }

                            autoHeight
                        />
                    </Grid>
                    <Dialog
                        open={openDialog}
                        onClose={handleCloseDialog}
                        maxWidth="md"
                        fullWidth={true}
                        PaperProps={{
                            component: "form"
                        }}
                    >
                        <DialogTitle>Location Details</DialogTitle>
                        <DialogContent>
                            <Box sx={{ display: "flex" }} spacing={2}>
                                <TextField sx={{ mr: 2, width: 200 }}
                                    autoFocus
                                    required
                                    margin="dense"
                                    id="location"
                                    name="location"
                                    label="Location"
                                    type="text"
                                    defaultValue={openDialog ? selectedRow.location : ''}
                                    onChange={handleOnChange}
                                    variant="standard"
                                    inputProps={{ maxLength: 15 }}
                                />
                                <TextField sx={{ flexGrow: 1 }}
                                    margin="dense"
                                    id="description"
                                    name="description"
                                    label="Description"
                                    type="text"
                                    defaultValue={openDialog ? selectedRow.description : ''}
                                    onChange={handleOnChange}
                                    variant="standard"
                                    inputProps={{ maxLength: 50 }}
                                />
                            </Box>
                            <DialogActions>
                                <Button onClick={handleCloseDialog}>Cancel</Button>
                                <Button type="submit" variant="contained">Save</Button>
                            </DialogActions>
                        </DialogContent>
                    </Dialog>
                    <ConfirmDialog open={showConfirmDelete} title={"Confirm Delete"}
                        content="Are you sure you want to delete this record?"
                        onNoClick={() => setShowConfirmDelete(false)}
                        onYesClick={doDelete}
                        yesNo
                    />

                    <ConfirmDialog open={showConfirmClear} title={"Confirm Import"}
                        content="Are you sure you want to import locations from a file?"
                        detail="Click <strong>Yes</strong> to remove existing records and reimport. <br/ > Click <strong>No</strong> to keep existing and update or insert. <br /><br />"
                        onCancelClick={() => setShowConfirmClear(false)}
                        onNoClick={() => handleFileSelect(false)}
                        onYesClick={() => handleFileSelect(true)}
                        yesNoCancel
                    />
                </Container>
            </form>
        </>
    );
}

export default Locations;