import React from 'react';
import {
    Button, Box, Container, Typography, Divider, Dialog,
    DialogTitle, DialogContent, DialogActions,
    TextField,
    LinearProgress
} from '@mui/material';

import ApplicationContext from '../contexts/application';
import { CustomDataGrid } from '../components/CustomDataGrid';
import { deleteAlarmGroup, getAlarmGroups, saveAlarmGroup } from '../libs/apiCalls';
import ConfirmDialog from '../components/ConfirmDialog';
import { UserRoles } from '../libs/userRoles';

function AlarmGroups() {
    const { settings, setDialog, setSnackbar } = React.useContext(ApplicationContext);
    const [gridRows, setGridRows] = React.useState([]);
    const [gridSelection, setGridSelection] = React.useState([]);
    const [selectedRow, setSelectedRow] = React.useState({});
    const [openDialog, setOpenDialog] = React.useState(false);
    const [openDelete, setOpenDelete] = React.useState(false);
    const [reload, setReload] = React.useState(false);
    const [loading, setLoading] = React.useState(true);
    const isOEM = React.useRef(settings.roles.includes(UserRoles.OEM.label));

    const columns = [
        { field: 'id', headerName: 'ID' },
        { field: 'name', headerName: 'Name', flex: 0.3 },
        { field: 'description', headerName: 'Description', flex: 0.5 },
        { field: 'email', headerName: 'Email', flex: 1 }
    ];

    const clearSelection = () => {
        setGridSelection([]);
        setSelectedRow({});
    };

    const handleAddClick = () => {
        const group = {
            id: 0,
            name: '',
            description: '',
            email: '',
            createdBy: settings.userName
        };

        setSelectedRow(group);
        setOpenDialog(true);
        clearSelection();
    };

    const handleEdit = () => {
        if (Object.keys(selectedRow).length > 0) {
            setOpenDialog(true);
        }
    };

    const handleDelete = () => {
        if (Object.keys(selectedRow).length > 0) {
            setOpenDelete(true);
        }
    };

    const handleRowSelected = (ids) => {
        setGridSelection(ids);

        if (ids.length > 0) {
            var row = gridRows.find((row) => row.id === ids[0]);
            setSelectedRow(row);
        }
    };

    const handleCellDoubleClick = (params, event) => {
        setOpenDialog(true);
    };

    const getData = () => {
        setLoading(true);

        getAlarmGroups(settings.token, 
            (response) => {
                if (!response.data.error) {
                    setGridRows(JSON.parse(response.data.result));
                }
                else {
                    setDialog(response.data.errors[0], true);
                }

                setLoading(false);
            },
            (error) => {
                setDialog(error.message, true);
                setLoading(false);
            }
        )
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setReload(!reload);

        clearSelection();
    };

    const handleSubmitDialog = (event) => {
        event.preventDefault();
        setOpenDialog(false);

        if (Object.keys(selectedRow).length > 0) {
            setLoading(true);

            if (selectedRow.id > 0) {
                selectedRow.updatedBy = settings.userName;
            }
            else {
                selectedRow.createdBy = settings.userName;
            }

            saveAlarmGroup(settings.token, JSON.stringify(selectedRow),
                (response) => {
                    if (!response.data.error) {
                        clearSelection();
                        setSnackbar('Record saved/updated successfully.');
                        setReload(!reload);
                    }
                    else {
                        setDialog(response.data.errors[0], true);
                    }

                    setLoading(false);
                },
                (error) => {
                    setDialog(error.message, true);
                    setLoading(false);
                }
            );
        }
    }

    const handleOnChange = (event) => {
        selectedRow[event.target.name] = event.target.value;
    };

    const handleCancelDelete = () => {
        setOpenDelete(false);
        setReload(!reload);
    };
    
    const doDelete = () => {
        setOpenDelete(false);
        setLoading(true);

        if (Object.keys(selectedRow).length > 0) {
            deleteAlarmGroup(settings.token, JSON.stringify(selectedRow),
                (response) => {
                    if (!response.data.error) {
                        clearSelection();
                        setSnackbar('Alarm Group deleted successfully.');
                        setReload(!reload);
                    }
                    else {
                        setDialog(response.data.errors[0], true);
                    }

                    setLoading(false);
                },
                (error) => {
                    setDialog(error.message, true);
                    setLoading(false);
                }
            );
        }
    };

    React.useEffect(() => {
        if (Object.keys(settings).length > 0) {
            getData();
        }
    }, [settings, reload]);

    return (
        <>
            <Box sx={{ display: 'flex', mb: 2 }}>
                <Box sx={{ width: "50%" }}>
                    <Typography sx={{ marginLeft: "5px", fontWeight: '400' }} style={{ fontSize: "25px" }}>Alarm Groups</Typography>
                    <Typography sx={{ marginLeft: "5px", fontWeight: '300' }} style={{ fontSize: "15px" }}>Define groups to nofify for incoming alarm types.</Typography>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: "50%", mb: 4 }}>
                    <Button variant="outlined" color="error" 
                        style={{ width: "120px" }} 
                        onClick={handleDelete}
                        sx={{ display: isOEM ? 'display' : 'none' }}
                    >
                        Delete
                    </Button>
                    <Button variant="outlined" style={{ width: "120px", marginLeft: "7px" }} onClick={handleEdit}>Edit</Button>
                    <Button variant="contained" color="success"
                        style={{ width: "120px", marginLeft: "7px" }}
                        onClick={handleAddClick}
                    >
                        Add
                    </Button>
                </Box>
            </Box>
            <Divider sx={{ mb: 4 }} />
            <Container maxWidth="xl">
                <LinearProgress sx={{ display: loading ? 'display' : 'none' }} />
                <Box sx={{ height: '100%', width: '100%' }}>
                    <CustomDataGrid
                        columns={columns}
                        rows={gridRows}
                        getRowId={(row) => row.id}
                        onCellDoubleClick={handleCellDoubleClick}

                        pagination
                        pageSizeOptions={[10, 20, 30]}
                        onRowSelectionModelChange={handleRowSelected}
                        initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: 20,
                                },
                            },
                        }}

                        getRowClassName={(params) =>
                            params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                        }

                        rowSelectionModel={gridSelection}
                        autoHeight
                    />
                </Box>
                <Dialog
                    open={openDialog}
                    onClose={handleCloseDialog}
                    maxWidth="sm"
                    fullWidth={true}
                    PaperProps={{
                        component: "form",
                        onSubmit: (handleSubmitDialog)
                    }}
                >
                    <DialogTitle>Alarm Group</DialogTitle>
                    <DialogContent>
                        <Box sx={{ display: "flex" }} spacing={2}>
                            <TextField sx={{ mr: 2, flexGrow: 0.15 }}
                                autoFocus
                                required
                                margin="dense"
                                id="name"
                                name="name"
                                label="Name"
                                type="text"
                                defaultValue={openDialog ? selectedRow.name : ''}
                                onChange={handleOnChange}
                                variant="standard"
                            />
                            <TextField sx={{ flexGrow: 1 }}
                                margin="dense"
                                id="description"
                                name="description"
                                label="Description"
                                type="text"
                                defaultValue={openDialog ? selectedRow.description : ''}
                                onChange={handleOnChange}
                                variant="standard"
                            />
                        </Box>
                        <Box sx={{ display: "flex", mt: 2 }} spacing={2}>
                        <TextField sx={{ flexGrow: 1 }}
                                required
                                margin="dense"
                                id="email"
                                name="email"
                                label="Email"
                                type="text"
                                defaultValue={openDialog ? selectedRow.email : ''}
                                onChange={handleOnChange}
                                variant="standard"
                            />
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseDialog}>Cancel</Button>
                        <Button type="submit" variant="contained">Save</Button>
                    </DialogActions>
                </Dialog>
            </Container>
            <ConfirmDialog open={openDelete} title={"Confirm Delete"}
                content={"Are you sure you want to remove this alarm group? (Note: this cannot be undone)"}
                onNoClick={handleCancelDelete}
                onYesClick={doDelete}
                yesNo
            />
        </>
    )
}

export default AlarmGroups;
