import { createContext, useEffect, useState } from "react";
import { getDataKeyAsync, setDataKeyAsync } from "../libs/localdb";
import mqtt from 'mqtt';

const ApplicationContext = createContext();

function ContextProvider({ children }) {
    const mqttServer = `${process.env.REACT_APP_MQTT_BROKER}`;
    const mqttUsername = `${process.env.REACT_APP_MQTT_USERNAME}`;
    const mqttPassword = `${process.env.REACT_APP_MQTT_PASSWORD}`;

    const alarmTopic = 'TwinWorx/Alarms'; 

    const [appSettings, setAppSettings] = useState({});
    const [dialogMessage, setDialogMessage] = useState('');
    const [showDialog, setShowDialog] = useState(false);
    const [isError, setIsError] = useState(false);
    const [mqttBroker, setMqttBroker] = useState(null);
    const [badgeCount, setBadgeCount] = useState(0);
    const [onMessage, setOnMessage] = useState({});

    const [showSnackbar, setShowSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');

    const init = async () => {
        let defData = {
            "token": "",
            "userName": "",
            "password": "",
            "pageTitle": "",
            "name": "",
            "oauth": ""
        };

        await getDataKeyAsync("appSettings")
            .then((result) => {
                result != null ? setAppSettings(result) : setAppSettings(defData);                
            })
            .catch(() => {
                setAppSettings(defData);
            });
    };

    const saveData = async (data) => {
        await setDataKeyAsync("appSettings", data)
            .then(() => {
                setAppSettings(data);
            })
            .catch((error) => {
                console.error(error);
            })
    }

    const context = {
        settings: appSettings,
        saveSettings: (data) => saveData(data),

        badgeCount,
        setBadgeCount,

        mqttBroker: mqttBroker,
        onMessage,
        
        dialogMessage,
        showDialog: showDialog,
        isError,

        setDialog: (message, isError = false) => {
            setDialogMessage(message);
            setShowDialog(true);
            setIsError(isError);
        },

        clearDialog: () => {
            setDialogMessage('');
            setShowDialog(false);
            setIsError(false);
        },

        snackbar: showSnackbar,
        snackbarMessage,
        setShowSnackbar,

        setSnackbar: (message) => {
            setSnackbarMessage(message);
            setShowSnackbar(true);
        }
    };

    const connectToBroker = () => {
        if (mqttBroker) {
            mqttBroker.end();
        }

        const broker = mqtt.connect(mqttServer, {
            username: mqttUsername,
            password: mqttPassword,
        });

        broker.on('connect', () => {
            broker.subscribe(alarmTopic, (error) => {
                if (error) {
                    console.log(error);
                }
            });

            setMqttBroker(broker);
        });

        broker.on('message', (topic, message) => {
            setOnMessage({topic, message});
        });        

        broker.on('error', (err) => {
            console.error(err);
            broker.end();
        });
    };

    useEffect(() => {
        init();
        connectToBroker();
    }, []);

    return (
        <ApplicationContext.Provider value={ context }>
            {children}
        </ApplicationContext.Provider>
    );
}

export { ContextProvider };
export default ApplicationContext;