import React from 'react';

import {
    Box, Divider, Grid,
    IconButton,
    LinearProgress,
    Stack,
    Tooltip,
    Typography
} from '@mui/material';

import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import StopCircleOutlinedIcon from '@mui/icons-material/StopCircleOutlined';
import RestartAltOutlinedIcon from '@mui/icons-material/RestartAltOutlined';

import ApplicationContext from '../contexts/application';
import { CustomDataGrid } from '../components/CustomDataGrid';
import { getIntegrations, getIntegrationStatus, getSystemServices, restartIntegration, restartService, startIntegration, startService, stopIntegration, stopService } from '../libs/apiCalls';
import ConfirmDialog from '../components/ConfirmDialog';

function ServiceHealth() {
    const defaultPageSize = 5;
    const integrationId = React.useRef(0);

    const { settings, setDialog } = React.useContext(ApplicationContext);
    const [showConfirmStop, setShowConfirmStop] = React.useState(false);
    const [showConfirmRestart, setShowConfirmRestart] = React.useState(false);
    const [showConfirmServiceStop, setShowConfirmServiceStop] = React.useState(false);
    const [showConfirmServiceRestart, setShowConfirmServiceRestart] = React.useState(false);
    const [loading, setLoading] = React.useState(true);

    const [agentData, setAgentData] = React.useState([]);
    const agentColumns = [
        { field: 'integrationId', headerName: 'ID' },
        { field: 'name', headerName: 'Name', flex: 0.3 },
        { field: 'description', headerName: 'Description', flex: 1 },
        { field: 'direction', headerName: 'Direction' },
        { field: 'protocol', headerName: 'Protocol' },
        { field: 'port', headerName: 'Port' },
        { field: 'topic', headerName: 'Topic', flex: 0.3 },
        {
            field: 'status', headerName: 'Active',
            renderCell: (props) => {
                return (
                    <Stack>
                        <FiberManualRecordIcon
                            sx={{
                                color: props.value ? '#4caf50' : '#d9182e',
                                m: 'auto',
                                mt: 1.5
                            }}
                        />
                    </Stack>
                );
            }
        },
        {
            field: 'actions', headerName: '', filterable: false, flex: 0.3,
            renderCell: (props) => {
                return (
                    <Stack direction='row' spacing={3} sx={{ mt: 0.5 }}>
                        <IconButton disabled={props.value} onClick={() => handleAgentStart(props.id)}>
                            <Tooltip title='Start'>
                                <PlayCircleOutlineIcon />
                            </Tooltip>
                        </IconButton>

                        <IconButton disabled={!props.value} onClick={() => handleAgentStop(props.id)}>
                            <Tooltip title='Stop'>
                                <StopCircleOutlinedIcon />
                            </Tooltip>
                        </IconButton>

                        <IconButton disabled={!props.value} onClick={() => handleAgentRestart(props.id)}>
                            <Tooltip title='Restart'>
                                <RestartAltOutlinedIcon />
                            </Tooltip>
                        </IconButton>
                    </Stack>
                );
            }
        },
    ];

    const [serviceData, setServiceData] = React.useState([]);
    const serviceColumns = [
        { field: 'id', headerName: 'Id' },
        { field: 'name', headerName: 'Name', flex: 0.2 },
        { field: 'description', headerName: 'Description', flex: 0.3 },
        { field: 'ipAddress', headerName: 'IP Adress', flex: 0.2 },
        { field: 'port', headerName: 'Port', flex: 0.2 },
        {
            field: 'status', headerName: 'Running',
            renderCell: (props) => {
                return (
                    <Stack>
                        <FiberManualRecordIcon
                            sx={{
                                color: props.value ? '#4caf50' : '#d9182e',
                                m: 'auto',
                                mt: 1.5
                            }}
                        />
                    </Stack>
                );
            }
        },
        // {
        //     field: 'actions', headerName: '', filterable: false, flex: 0.25,
        //     renderCell: (props) => {
        //         return (
        //             <Stack direction='row' spacing={3} sx={{ mt: 0.5 }}>
        //                 <IconButton disabled={!props.row.tags} onClick={() => handleServiceStart(props.row.tags)}>
        //                     <Tooltip title='Start' >
        //                         <PlayCircleOutlineIcon />
        //                     </Tooltip>
        //                 </IconButton>

        //                 <IconButton disabled={!props.row.tags} onClick={() => handleServiceStop(props.row.tags)}>
        //                     <Tooltip title='Stop'>
        //                         <StopCircleOutlinedIcon />
        //                     </Tooltip>
        //                 </IconButton>

        //                 <IconButton disabled={!props.row.tags} onClick={() => handleServiceRestart(props.row.tags)}>
        //                     <Tooltip title='Restart'>
        //                         <RestartAltOutlinedIcon />
        //                     </Tooltip>
        //                 </IconButton>
        //             </Stack>
        //         );
        //     }
        // }
    ];

    const busColumns = [
        { field: 'id', headerName: 'ID' },
        { field: 'topic', headerName: 'Topic', flex: 0.75 },
        { field: 'partitions', headerName: 'Partitions' },
        { field: 'messages', headerName: 'Messages' },
        { field: 'committed', headerName: 'Processed' },
        { field: 'queued', headerName: 'In Queue' },
        { field: 'status', headerName: 'Status' }
    ];

    const reloadPage = () => {
        window.location.reload();
    };

    const handleAgentStop = ((id) => {
        integrationId.current = id;
        setShowConfirmStop(true);
    });

    const handleAgentRestart = ((id) => {
        integrationId.current = id;
        setShowConfirmRestart(true);
    });

    const handleAgentStart = ((id) => {
        startIntegration(settings.token, id,
            (response) => {
                if (!response.data.error) {
                    reloadPage();
                }
                else {
                    setDialog(response.data.errors[0], true);
                }
            },
            (error) => {
                setDialog(error.message, true);
            }
        );
    });

    const stopAgent = () => {
        setShowConfirmStop(false);

        stopIntegration(settings.token, integrationId.current,
            (response) => {
                if (!response.data.error) {
                    reloadPage();
                }
                else {
                    setDialog(response.data.errors[0], true);
                }
            },
            (error) => {
                setDialog(error.message, true);
            }
        );
    }

    const restartAgent = () => {
        setShowConfirmRestart(false);

        restartIntegration(settings.token, integrationId.current,
            (response) => {
                if (!response.data.error) {
                    reloadPage();
                }
                else {
                    setDialog(response.data.errors[0], true);
                }
            },
            (error) => {
                setDialog(error.message, true);
            }
        );
    }

    const getIntegrationData = () => {
        getIntegrations(settings.token,
            (response) => {
                if (!response.data.error) {
                    setAgentData(JSON.parse(response.data.result));
                }
                else {
                    setDialog(response.data.errors[0], true);
                }
            },
            (error) => {
                setDialog(error.message, true);
            }
        );
    };

    const getServiceData = () => {
        setLoading(true);

        getSystemServices(settings.token,
            (response) => {
                if (!response.data.error) {
                    setServiceData(JSON.parse(response.data.result));
                }
                else {
                    setDialog(response.data.errors[0], true);
                }

                setLoading(false);
            },
            (error) => {
                setDialog(error.message, true);
                setLoading(false);
            }
        );
    };

    const handleServiceRestart = (id) => {
        integrationId.current = id;
        setShowConfirmServiceRestart(true);
    };

    const handleServiceStart = (id) => {
        startService(settings.token, id,
            (response) => {
                alert(response.data.result);
            },
            (error) => {
                setDialog(error.message, true);
            }
        );
    };

    const handleServiceStop = (id) => {
        integrationId.current = id;
        setShowConfirmServiceStop(true);
    };

    const doServiceRestart = () => {
        restartService(settings.token, integrationId.current,
            (response) => {
                setShowConfirmServiceRestart(false);
            },
            (error) => {
                setDialog(error.message, true);
                setShowConfirmServiceRestart(false);
            }
        );
    };

    const doServiceStop = () => {
        stopService(settings.token, integrationId.current,
            (response) => {
                setShowConfirmServiceStop(false);
            },
            (error) => {
                setDialog(error.message, true);
                setShowConfirmServiceStop(false);
            }
        );
    };

    React.useEffect(() => {
        if (Object.keys(settings).length > 0) {
            getIntegrationData();
            getServiceData();
        }
    }, [settings]);

    React.useEffect(() => {
        if (agentData.length > 0) {
            getIntegrationStatus(settings.token,
                (response) => {
                    if (!response.data.error) {
                        const stats = JSON.parse(response.data.result);
                        const map = new Map(Object.entries(stats));

                        agentData.forEach((row) => {
                            row.status = map.get(row.integrationId.toString());
                            row.actions = map.get(row.integrationId.toString());
                        });
                    }
                    else {
                        setDialog(response.data.errors[0], true);
                    }
                },
                (error) => {
                    setDialog(error.message, true);
                }
            );
        }
    }, [agentData]);

    return (
        <>
            <Box sx={{ mb: 2, height: '60px' }}>
                <Box sx={{ width: '50%' }}>
                    <Typography sx={{ marginLeft: '5px', fontWeight: '400' }} style={{ fontSize: '25px' }}>Service Health</Typography>
                    <Typography sx={{ marginLeft: '5px', fontWeight: '300' }} style={{ fontSize: '15px' }}>Displays all system and service related information.</Typography>
                </Box>
                <Box sx={{ justifyContent: 'flex-end', width: '50%', mb: 8 }}>
                </Box>
            </Box>
            <Divider sx={{ mb: 4 }} />
            <Grid container gap={2} sx={{ width: '100%' }}>
                <Box sx={{ width: '100%' }}>
                    <Typography sx={{ marginLeft: '5px', fontWeight: '400' }} style={{ fontSize: '20px' }}>System Services</Typography>
                </Box>
                <LinearProgress sx={{ width: '100%', display: loading ? 'block' : 'none' }} />
                <CustomDataGrid
                    columns={serviceColumns}
                    rows={serviceData}
                    pageSizeOptions={[10]}
                    getRowId={(row) => row.id}

                    disableRowSelectionOnClick
                    disableColumnFilter
                    sx={{ minHeight: 200, display: loading ? 'none' : 'block' }}
                />

            </Grid>
            <Grid container gap={2} sx={{ mt: 5, width: '100%' }}>
                <Box sx={{ width: '100%' }}>
                    <Typography sx={{ marginLeft: '5px', fontWeight: '400' }} style={{ fontSize: '20px' }}>Routing Agents</Typography>
                </Box>
                <CustomDataGrid
                    columns={agentColumns}
                    rows={agentData}
                    pageSizeOptions={[10]}
                    getRowId={(row) => row.integrationId}

                    disableRowSelectionOnClick
                    disableColumnFilter
                    sx={{ minHeight: 200 }}
                />
            </Grid>
            <Grid container gap={2} sx={{ mt: 5, width: '100%', display: 'none' }}>
                <Box sx={{ width: '100%' }}>
                    <Typography sx={{ marginLeft: '5px', fontWeight: '400' }} style={{ fontSize: '20px' }}>Message Bus</Typography>
                </Box>
                <CustomDataGrid
                    columns={busColumns}
                    rows={[]}
                    pageSizeOptions={[10]}

                    disableColumnFilter
                    sx={{ minHeight: 200 }}
                />
            </Grid>

            <ConfirmDialog open={showConfirmStop} title={"Confirm Stop"}
                content={"Are you sure you want to stop this integration? (Note: any workflows using this may be affected.)"}
                onNoClick={() => setShowConfirmStop(false)}
                onYesClick={stopAgent}
                yesNo
            />
            <ConfirmDialog open={showConfirmRestart} title={"Confirm Restart"}
                content={"Are you sure you want to restart this integration? (Note: any workflows using this may be affected.)"}
                onNoClick={() => setShowConfirmRestart(false)}
                onYesClick={restartAgent}
                yesNo
            />
            <ConfirmDialog open={showConfirmServiceStop} title={"Confirm Stop"}
                content={"Are you sure you want to stop this service? (Note: any workflows using this may be affected.)"}
                onNoClick={() => setShowConfirmServiceStop(false)}
                onYesClick={doServiceStop}
                yesNo
            />
            <ConfirmDialog open={showConfirmServiceRestart} title={"Confirm Restart"}
                content={"Are you sure you want to restart this service? (Note: any workflows using this may be affected.)"}
                onNoClick={() => setShowConfirmServiceRestart(false)}
                onYesClick={doServiceRestart}
                yesNo
            />

        </>
    );
}

export default ServiceHealth;